import RtActionButton from "components/button/RtActionButton"
import RtButton from "components/button/RtButton"
import CopyToClipBoard from "components/copy-to-clipboard"
import RTQrScanner from "components/qr-code-scanner/RTQrScanner"
import { TOASTER_ID } from "constants/global.constants"
import useScanTicket from "hooks/api/useScanTicket"
import useDisclosure from "hooks/useDisclosure"
import { useNavigate } from "react-router-dom"
import { cn } from "utils/cn.util"
import { showToaster } from "utils/show-toaster.util"
import { getEventDetailsURL } from "utils/url.generator.util"

interface AdminTicketActionsProps {
  overrideClass?: string
  slug: string
  permissions: {
    canSellTickets: boolean
    canApproveWithdrawalRequest: boolean
  }
}

function AdminTicketActions({
  overrideClass,
  slug,
  permissions,
}: AdminTicketActionsProps) {
  const navigate = useNavigate()
  const { isOpen: showQrScanner, onToggle: toggleQrScanner } = useDisclosure()

  const scanQueryClient = useScanTicket()
  const handleSuccessFullQrScan = (scanResult: string) => {
    scanQueryClient.mutate(scanResult)
  }

  const handleSellTickets = () => {
    navigate(`/events/${slug}/?sell-tickets=1`)
  }

  return (
    <>
      <div
        className={cn(`flex justify-between items-center gap-4`, overrideClass)}
      >
        {permissions.canSellTickets && (
          <div className="flex gap-4 items-center">
            <RtButton onClick={toggleQrScanner}>Scan QR</RtButton>
            <RtButton onClick={handleSellTickets}>Sell tickets</RtButton>
          </div>
        )}

        {permissions.canApproveWithdrawalRequest && (
          <RtActionButton
            name="Mark withdrawal as paid"
            handleClick={() => {}}
          />
        )}

        <CopyToClipBoard
          copyText={getEventDetailsURL(slug)}
          successMsg="The event link has successfully been copied,you can now share it with your friends & family."
        />
      </div>
      {showQrScanner && (
        <RTQrScanner
          showScanner={showQrScanner}
          toggleScanner={toggleQrScanner}
          qrCodeSuccessCallback={handleSuccessFullQrScan}
          // TODO: Implement error handling for QR code scanner and capture the error in sentry.
          qrCodeErrorCallback={(_) => {
            showToaster(
              "error",
              "An error occurred while scanning the QR code, please try again.",
              2000,
              undefined,
              TOASTER_ID.scanTicketError,
            )
          }}
        />
      )}
    </>
  )
}

export default AdminTicketActions
