import { Zap } from "react-feather"
import { useParams } from "react-router-dom"
import RtButton from "components/button/RtButton"
import PageNav from "components/nav/PageNav"
import SectionContainer from "components/layout/SectionContainer"
import ContentContainer from "components/layout/ContentContainer"
import EventCard from "components/event-card/EventCard"
import PurchaseTicket from "components/ticket/PurchaseTicket"
import { useEventBySlug } from "hooks/api/useEventBySlug"
import TicketTotal from "./ticket-total"
import { geSecureTicketFromCart } from "utils/cart.util"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import Spinner from "components/loader/Spinner"
import { TicketGroupInterface } from "types/index.types"
import { useSelector } from "react-redux"
import ErrorFallback from "components/fallback/ErrorFallback"
import { canProceedToCheckout } from "./util"
import { useProceedToCheckout } from "hooks/api/useProceedToCheckout"
import CopyToClipBoard from "components/copy-to-clipboard"
import { getEventDetailsURL } from "utils/url.generator.util"
import TicketStatusWrapper from "components/ticket/TicketStatusWrapper"
import ImageCarousel from "components/image-display/image-carousel"
import { useState } from "react"
import { getLockRemainingSeconds } from "utils/get-lock-remaining-time.util"
import ExistingCartModal from "components/modal/ExistingCartModal"

function EventDetails() {
  const { slug } = useParams()

  const cart = useSelector((state: any) => state.cart)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lockIdentifier, setLockIdentifier] = useState("")
  const [checkOutUrl, setCheckOutUrl] = useState("")
  const [otherCartSlug, setOtherCartSlug] = useState("")
  const [otherCartEventName, setOtherCartEventName] = useState("")

  const slugInCart = cart[slug!]
  const proceedCheckoutQueryClient = useProceedToCheckout()
  const handleOpenCartCodeModal = () => {
    setIsModalOpen(true)
  }

  const canOpenCart = () => {
    const keys = Object.keys(cart)
    const otherCartDetails = cart ? cart[keys[0]] : null
    const url = otherCartDetails?.checkOutUrl
    if (!url) return false

    const splitUrl = url.split("/")
    const slugIdentifier = splitUrl[splitUrl.length - 1]
    const expiresAt = splitUrl[splitUrl.length - 2]

    if (!slugIdentifier || !expiresAt) return false
    const remTimeInSeconds = getLockRemainingSeconds(expiresAt!)
    if (remTimeInSeconds > 0) {
      setLockIdentifier(slugIdentifier!)
      setCheckOutUrl(url)
      setOtherCartSlug(otherCartDetails?.slug!)
      setOtherCartEventName(otherCartDetails?.eventName!)
      handleOpenCartCodeModal()
      return true
    }

    return false
  }

  const proceedToCheckout = () => {
    if (canOpenCart()) return
    const tickets = geSecureTicketFromCart(slugInCart)
    proceedCheckoutQueryClient.mutate({
      slug: slug!,
      tickets,
      eventName: data?.data.event.name,
    })
  }
  const { data, isLoading, error, refetch } = useEventBySlug(slug!)

  if (isLoading) return <Spinner />

  if (error) {
    return <ErrorFallback />
  }

  const canProceed = canProceedToCheckout(data?.data.ticket_groups)

  return (
    <div>
      {data ? (
        <>
          <SectionContainer overrideClass="my-0">
            <PageNav>{data?.data.event.name || "View Event"}</PageNav>
          </SectionContainer>

          <SectionContainer overrideClass="flex items-center justify-end my-0 md:mb-2">
            <CopyToClipBoard
              copyText={getEventDetailsURL(slug!)}
              successMsg="The event link has successfully been copied,you can now share it with your friends & family."
            />
          </SectionContainer>

          <div className="grid lg:grid-cols-2">
            <SectionContainer overrideClass="my-0">
              <div className="flex-row md:flex-col gap-4">
                {/* <img
                  src={data?.data.event.image_url}
                  alt={`${data?.data.event.name} - Event Image`}
                  className="w-full max-w-1/2 h-full max-h-[400px] rounded-md object-cover"
                /> */}
                <ImageCarousel
                  images={[
                    ...(data?.data?.event?.image_urls || []),
                    ...(data?.data?.event?.image_url
                      ? [data.data.event.image_url]
                      : []),
                  ]}
                  eventName={`${data?.data.event.name} - Event Image`}
                />

                <EventCard preview showEventAbout event={data.data.event} />
              </div>
            </SectionContainer>

            <div>
              <SectionContainer
                sectionTitle="Purchase Ticket"
                overrideClass="lg:mt-0"
              >
                <ContentContainer overrideClass="w-full flex md:flex-col gap-4">
                  {data.data.ticket_groups.map(
                    (ticketGroup: TicketGroupInterface, index: number) => (
                      <div className="relative">
                        {/* TODO: banner should contain different texts for different reasons e.g Sold out,expired,not started  */}
                        {/* {!canProceed && (
                        <RtBanner
                          overrideWrapperStyles="mb-4"
                          bannerType="error"
                          title={
                            ticketGroup?.status_text || "Tickets Unavailable"
                          }
                          description="Tickets for
this event are no longer available for purchase."
                        />
                      )} */}
                        <TicketStatusWrapper
                          status={ticketGroup.status}
                          status_text={ticketGroup.status_text}
                        />

                        <PurchaseTicket
                          ticketGroup={ticketGroup}
                          key={`ticket-group-${index}`}
                          slug={slug!}
                          showTicketCartCount
                          disabled={!canProceed}
                          eventName={data.data.event.name!}
                          canOpenCart={canOpenCart}
                        />
                      </div>
                    ),
                  )}
                </ContentContainer>
              </SectionContainer>

              <SectionContainer>
                <TicketTotal slug={slug!} />
              </SectionContainer>

              <SectionContainer>
                <>
                  {canProceed && (
                    <RtButton
                      overrideClass={`w-full py-4 `}
                      icon={<Zap color="white" className="mr-2" />}
                      onClick={() => proceedToCheckout()}
                      disabled={!canProceed}
                    >
                      Buy Ticket
                    </RtButton>
                  )}
                </>
              </SectionContainer>
            </div>
          </div>

          <ActionLoadingModal
            isOpen={proceedCheckoutQueryClient.isPending}
            loadingText="Please wait as your tickets are being secured..."
          />
        </>
      ) : (
        <h1>No event data available</h1>
      )}
      <ExistingCartModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        checkOutUrl={checkOutUrl!}
        slug={otherCartSlug!}
        lock_identifier={lockIdentifier!}
        eventName={otherCartEventName!}
        checkout={false}
        refetch={refetch}
      />
    </div>
  )
}

export default EventDetails
