import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { useNavigate } from "react-router-dom"
import { useDropTickets } from "hooks/api/useDropTickets"

function ExistingCartModal({
  isOpen,
  onClose,
  checkOutUrl,
  slug,
  lock_identifier,
  checkout,
  eventName,
  refetch,
}: {
  isOpen: boolean
  onClose: () => void
  checkOutUrl?: string
  slug: string
  lock_identifier: string
  checkout: boolean
  eventName?: string
  refetch?: () => void
}) {
  const navigate = useNavigate()
  const dropTicketQueryClient = useDropTickets()
  const onClearCart = () => {
    onClose()
    dropTicketQueryClient.mutate({
      slug,
      lock_identifier,
      checkout,
      refetch: refetch!,
    })
  }
  const onYes = () => {
    if (checkout) {
      onClose()
      return
    }
    navigate(checkOutUrl!)
  }

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <p className="text-center text-rt-gray">
        {checkout
          ? "Going back will clear your existing cart. Are you sure you want to proceed?"
          : `You have existing secured tickets with ${eventName}. Would you like to proceed to checkout or clear the tickets?`}
      </p>

      {checkout ? (
        <div className={`mt-8 flex justify-between`}>
          <RtButton
            onClick={onYes}
            variant="outline"
            overrideClass="border border-rt-red"
            textOverrideClass="text-rt-red"
          >
            No
          </RtButton>

          <RtButton variant="solid" onClick={onClearCart}>
            Proceed to Ticket Selection
          </RtButton>
        </div>
      ) : (
        <div className={`mt-8 flex justify-between`}>
          <RtButton
            onClick={onClearCart}
            variant="outline"
            overrideClass="border border-rt-red"
            textOverrideClass="text-rt-red"
          >
            Clear Tickets
          </RtButton>
          <RtButton variant="solid" onClick={onYes}>
            Proceed to Checkout
          </RtButton>
        </div>
      )}
    </BaseModal>
  )
}

export default ExistingCartModal
