import { formatDateToDisplay } from "utils/date-formatter.util"

interface TicketScanItemInterface {
  scanned_at: string
  ticket_code: string
  scanned_by: string
}

interface TicketScansProps {
  scans: TicketScanItemInterface[]
}

function TicketScans({ scans }: TicketScansProps) {
  if (scans?.length === 0 || !scans) {
    return (
      <p className="font-poppins text-rt-gray">
        No ticket has been scanned so far.
      </p>
    )
  }

  return (
    <div className="font-poppins">
      <div className="grid grid-cols-3 font-semibold">
        <span>Time:</span>
        <span>Name:</span>
        <span>Ticket Code:</span>
      </div>

      <div>
        {scans.map((row, index) => (
          <TicketScanRow key={index} rowValues={row} />
        ))}
      </div>
    </div>
  )
}

export default TicketScans

function TicketScanRow({ rowValues }: { rowValues: TicketScanItemInterface }) {
  return (
    <div className="grid grid-cols-3 gap-2 mt-2 text-rt-gray border-b border-rt-gray border-opacity-20 pb-2">
      <i>{formatDateToDisplay(new Date(rowValues.scanned_at))}</i>
      <i>{rowValues.scanned_by}</i>
      <i>{rowValues.ticket_code}</i>
    </div>
  )
}
