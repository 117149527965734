import { cn } from "utils/cn.util"

function TicketsStats({
  stat,
  overrideClass = "",
}: {
  stat: {
    value: number
    total: number
    statDisplayName: string
    percentage: string
  }
  overrideClass?: string
}) {
  return (
    <div
      className={cn(
        "border border-rt-gray border-opacity-10 shadow-sm  py-4 relative bg-rt-white font-poppins flex flex-col items-center",
        overrideClass,
      )}
    >
      <div className="absolute -top-4 -right-2 rounded-full px-2 py-1 bg-rt-blue text-rt-white flex justify-center items-center p-2">
        <span className="text-xs"> {stat?.percentage || 0}</span>
      </div>
      <div className="flex items-center">
        <span className="font-bold text-xl">{stat?.value || 0}</span>
        <div className="5px bg-gray-200 w-px h-full mx-2"></div>
        <span className="font-bold text-xl">{stat?.total || 0}</span>
      </div>
      <span className="text-rt-gray mt-4">{stat?.statDisplayName || ""}</span>
    </div>
  )
}

export default TicketsStats
