import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useHostEvent() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationFn: async (formData: FormData) => {
      const response = await handlePostRequest("/events", formData)
      return response
    },
    onSuccess: (response) => {
      navigate(`/my-events/${response?.data.data.slug}`)
      showToaster("success", "Your event has successfully been created")
      dispatch(clearAllValidationErrors())
    },
    onError: (err: any) => {
      handleError(err)

      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
    },
  })

  return mutation
}
