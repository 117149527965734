import { Search, X } from "react-feather"
import { RtSearchProps } from "types/component-props.types"
import { useState } from "react"

function RtSearch({ handleSearch, searchValue, overrideClass }: RtSearchProps) {
  const [isFocused, setIsFocused] = useState(false)

  const handleSearchQ = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value)
  }

  const onSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch(searchValue)
    }
  }

  return (
    <div
      className={`container flex items-center justify-center border-2 rounded-lg bg-rt-white ${
        isFocused ? "border-rt-light-blue" : "border-rt-gray border-opacity-20"
      } ${overrideClass}`}
    >
      <input
        className="border-none outline-none rounded-lg w-full text-rt-gray-300 leading-tight focus:ring-0 placeholder-gray-450 placeholder:text-sm"
        type="text"
        placeholder="Find Events"
        value={searchValue}
        onChange={handleSearchQ}
        onKeyUp={onSearch}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {searchValue ? (
        <X
          className="text-rt-blue mr-2"
          size={20}
          strokeWidth={3}
          onClick={() => handleSearch("")}
        />
      ) : (
        <Search
          className="text-rt-blue mr-2"
          size={20}
          strokeWidth={2}
          onClick={() => handleSearchQ}
        />
      )}
    </div>
  )
}

export default RtSearch
