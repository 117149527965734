import { useEffect, RefObject } from "react"

const useScrollIntoView = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const element = ref?.current
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [ref?.current]) // Dependency on ref.current instead of ref
}

export default useScrollIntoView
