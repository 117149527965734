import { RtInputProps } from "types/component-props.types"
import RTFormHelperText from "./RTFormHelperText"
import RTError from "./RTError"

interface RtCheckBoxProps extends RtInputProps {
  rightText?: string
}

function RTCheckBox({
  inputContainerOverrideClass,
  label,
  labelOverrideClass,
  isRequired,
  formHelperText,
  errorFieldName,
  inputName,
  rightText,
  onInputChange,
  inputValue,
}: RtCheckBoxProps) {
  return (
    <div className={`flex-1 ${inputContainerOverrideClass}`}>
      {label && (
        <label
          className={`block text-left text-rt-gray mb-1 text-sm ${labelOverrideClass}`}
        >
          {label}

          {isRequired && <span className="text-rt-red">*</span>}
        </label>
      )}

      {formHelperText && <RTFormHelperText helperText={formHelperText} />}

      <div className="container">
        <input
          className={`border-2 border-rt-light-blue rounded-md  p-2 bg-rt-light-blue bg-opacity-10 text-rt-gray-300 leading-tight focus:outline-none focus:shadow-outline`}
          type="checkbox"
          name={inputName}
          checked={inputValue as boolean}
          onChange={(e) => {
            onInputChange && onInputChange({ [inputName]: e.target.checked })
          }}
        />

        {rightText && <span className="ml-2">{rightText}</span>}
      </div>

      <RTError inputName={errorFieldName || inputName} />
    </div>
  )
}

export default RTCheckBox
