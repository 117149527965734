import { showToaster } from "utils/show-toaster.util"

interface RtTabProps<K> {
  tab: K
  handleChangeTab: (tab: K) => void
  activeTab: K
  activeTabStyle?: string
  inactiveTabStyle?: string
  children?: React.ReactNode
  disabled?: boolean
  disabledReason?: string
}

function RtTab<T extends string>({
  children,
  tab,
  handleChangeTab,
  activeTab,
  activeTabStyle = "border-b-2 border-b-rt-blue text-rt-blue bg-rt-light-blue",
  inactiveTabStyle = "text-rt-gray",
  disabled,
  disabledReason,
  ...props
}: RtTabProps<T>) {
  const onChangeTab = () => {
    if (activeTab === tab) return

    if (!!disabled && disabledReason) {
      showToaster("error", disabledReason)
      return
    }

    if (!!disabled && !disabledReason) return

    handleChangeTab(tab)
  }
  return (
    <button
      disabled={!!disabled && !disabledReason}
      onClick={onChangeTab}
      className={`p-2 py-4 w-full font-semibold   ${
        activeTab?.toLocaleLowerCase() === tab.toLocaleLowerCase()
          ? activeTabStyle
          : inactiveTabStyle
      }`}
      {...props}
    >
      {children}
    </button>
  )
}

export default RtTab
