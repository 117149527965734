import {
  CLOSED,
  COMING_SOON,
  ON_GOING,
  SALES_CLOSED,
  SOLD_OUT,
} from "constants/global.constants"
import { TTicketStatus } from "types/enums"
import "assets/styles/stroke.scss"

interface TicketStatusWrapperProps {
  status_text: string
  status: TTicketStatus
}

const ticketStatus = {
  [COMING_SOON]: {
    color: "bg-sky-500",
    classProp: "coming-soon",
    border: "border-sky-500",
  },
  [CLOSED]: {
    color: "bg-red-500",
    classProp: "expired",

    border: "border-red-500",
  },
  [SOLD_OUT]: {
    color: "bg-gray-500",
    classProp: "sold-out",
    border: "border-rt-gray-700",
  },
  [SALES_CLOSED]: {
    color: "bg-gray-500",
    classProp: "unavailable",
    border: "border-rt-gray-500",
  },
}

function TicketStatusWrapper({
  status_text,
  status,
}: TicketStatusWrapperProps) {
  if (status === ON_GOING || !status) return null

  const ticketClass = ticketStatus[status]
  return (
    <div
      className={`flex items-center justify-center text-2xl whitespace-nowrap absolute inset-0 w-full h-full ${ticketClass.color} bg-opacity-20 rounded-xl ticket-status ${ticketClass.classProp}`}
    >
      <div
        className={`rotate-[-15deg] p-1 border-dashed border-2 ${ticketClass.border}`}
      >
        <p className={`z-10 border-2 border-dashed ${ticketClass.border} p-4`}>
          {status_text}
        </p>
      </div>
    </div>
  )
}

export default TicketStatusWrapper
