import { RtTextAreaProps } from "types/component-props.types"
import RTError from "./RTError"
import RTFormHelperText from "./RTFormHelperText"
import { useState } from "react"

function RtTextArea({
  label,
  inputName,
  inputPlaceholder,
  inputOverrideClass,
  labelOverrideClass,
  isRequired,
  onInputChange,
  icon,
  inputValue,
  inputContainerOverrideClass,
  formHelperText,
}: RtTextAreaProps) {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className={`flex-1 ${inputContainerOverrideClass}`}>
      {label && (
        <label
          className={`block text-left text-rt-gray text-sm  mb-1 ${labelOverrideClass}`}
        >
          {label}

          {isRequired && <span className="text-rt-red">*</span>}
        </label>
      )}
      {formHelperText && <RTFormHelperText helperText={formHelperText} />}
      <div
        className={`container relative flex items-center justify-center  border-1 ${
          isFocused
            ? "border-rt-light-blue"
            : "border-rt-gray border-opacity-20"
        }  rounded-lg bg-rt-white `}
      >
        {icon || null}
        <textarea
          className={`border-2 border-rt-light-blue rounded-lg w-full py-2 px-3 bg-rt-light-blue bg-opacity-10 text-rt-gray-300 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-450 ${
            icon ? "pl-10" : ""
          } placeholder:text-sm ${inputOverrideClass}`}
          {...(inputValue && { value: inputValue })}
          name={inputName}
          onChange={(e) =>
            onInputChange && onInputChange({ [inputName]: e.target.value })
          }
          placeholder={inputPlaceholder || ""}
          rows={3}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>

      <RTError inputName={inputName} />
    </div>
  )
}

export default RtTextArea
