import termsOfServiceHtml from "../../assets/html/rapidtickethub-terms.html?raw"
import DOMPurify from "dompurify"

function TermsOfService() {
  const sanitizedHtml = DOMPurify.sanitize(termsOfServiceHtml)
  return (
    <div className="p-10" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  )
}

export default TermsOfService
