import RtActionButton from "components/button/RtActionButton"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import RejectKycDetailsModal from "components/modal/RejectKycDetailsModal"
import { useApproveKyc } from "hooks/api/useReviewKyc"
import useDisclosure from "hooks/useDisclosure"

interface KycActionButtonsProps {
  hostId: number
  isKycApproved: boolean
}

function KycActionButtons({ hostId, isKycApproved }: KycActionButtonsProps) {
  // Approve kyc
  const approveKyc = useApproveKyc()
  const { isOpen, onToggle } = useDisclosure()

  const handleApproveKyc = () => {
    approveKyc.mutate(hostId)
  }
  return (
    <div className="flex justify-between">
      {!isKycApproved && (
        <RtActionButton
          overrideClass="text-rt-green border-rt-green"
          handleClick={handleApproveKyc}
          name="Approve KYC"
        />
      )}

      <RtActionButton
        overrideClass="text-rt-red border-rt-red"
        handleClick={onToggle}
        name="Reject KYC"
      />
      <RejectKycDetailsModal
        hostId={hostId}
        isOpen={isOpen}
        toggleModal={onToggle}
      />

      <ActionLoadingModal
        isOpen={approveKyc.isPending}
        loadingText="Please wait as the kyc details are being approved..."
      />
    </div>
  )
}

export default KycActionButtons
