import SectionContainer from "components/layout/SectionContainer"
import PageNav from "components/nav/PageNav"
import SalesGraph from "./sales-graph"
import SalesPieChart from "./sales-pie-chart"
import EventCard from "components/event-card/EventCard"
import { useParams } from "react-router-dom"
import { useEventBySlug } from "hooks/api/useEventBySlug"
import Spinner from "components/loader/Spinner"
import RevenueStats from "./revenue-stats"
import {
  transformSalesBarGraphData,
  transformSalesPieChartData,
} from "utils/transform-data"
import TicketsStats from "./tickets-stats"
import TicketScans from "./ticket-scans"
import RtTitle from "components/title/RtTitle"
import ErrorFallback from "components/fallback/ErrorFallback"
import {
  EVENT_CANCELLED,
  EVENT_REJECTED,
  EVENT_COMPLETED,
  EVENT_DRAFT,
  EVENT_IN_REVIEW,
  EVENT_PUBLISHED,
  REGULAR,
} from "constants/global.constants"
import { isAdmin } from "utils/user.util"
import { TicketGroupInterface } from "types/index.types"
import RtActionButton from "components/button/RtActionButton"
import PurchaseTicketContainer from "./purchase-ticket-container"
import { usePublishEvent } from "hooks/api/usePublishEvent"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import RtBanner from "components/banner"
import HostKyc from "components/host-kyc"
import ContentContainer from "components/layout/ContentContainer"
import { useViewKyc } from "hooks/api/useViewKyc"
import AdminTicketActions from "./admin-ticket-actions"
import AdminEventAction from "pages/my-event-details/admin-event-actions"
import HostEventActions from "./host-event-action"
import KycActionButtons from "./kyc-action-buttons"
import { ReactNode, useState } from "react"
import { useGetLoggedInUser } from "hooks/api/useGetLoggedInUser"
import { createUserType } from "utils/create-user-type"
import { handLogInUser } from "redux/features/user"
import { useDispatch } from "react-redux"
import { useGetLoggedInUserType } from "hooks/useUser"

function MyEventDetails() {
  const { slug } = useParams()
  const dispatch = useDispatch()
  const userType = useGetLoggedInUserType()
  const [getUserData, setGetUserData] = useState(false)

  const { data, isLoading, error } = useEventBySlug(slug!)

  const hostId = data?.data?.event.host_id
  const isInReview = data?.data?.event.status === EVENT_IN_REVIEW

  const { data: kycDetails, isLoading: isKycDetailsLoading } = useViewKyc(
    isInReview ? data?.data.event.host_id : null,
  )

  // Publish event
  const publishEventQueryClient = usePublishEvent(slug!)
  const handlePublish = () => {
    publishEventQueryClient.mutate()
  }

  const shouldFetchUser = userType === REGULAR && !getUserData

  const { data: userData, isSuccess: isGetLoggedUserSuccess } =
    useGetLoggedInUser(shouldFetchUser)
  if (shouldFetchUser && isGetLoggedUserSuccess) {
    const user_type = createUserType(userData.data)
    dispatch(handLogInUser({ ...userData.data, user_type }))
    setGetUserData(true)
  }
  // }
  const { pieSeries, labels } = transformSalesPieChartData(
    data?.data.ticket_group_sales,
  )
  const { time, sales } = transformSalesBarGraphData(data?.data.sales_overtime)

  const isDraft = data?.data?.event.status === EVENT_DRAFT
  const isPublished = data?.data?.event.status === EVENT_PUBLISHED
  const isCancelled = data?.data?.event.status === EVENT_CANCELLED
  const isEventRejected = data?.data.event.status === EVENT_REJECTED
  const isEventCompleted = data?.data.event.status === EVENT_COMPLETED

  const canViewStats = !isInReview && !isDraft && !isEventRejected

  const canApproveEvent = isAdmin() && isInReview
  const canEdit =
    data?.data.event?.is_host && (isInReview || isDraft || isPublished)
  const canSellTickets = isAdmin() && isPublished
  const canPublishEvent = data?.data.event?.is_host && isDraft
  const canApproveKyc = isAdmin() && isInReview
  const canCancelEvent = !isEventRejected && !isEventCompleted && !isCancelled
  const canRequestWithdrawal = data?.data.event?.is_host && isEventCompleted // pending full logic
  const canApproveWithdrawalRequest = isAdmin() && isEventCompleted // pending full logic
  const isKycApproved = kycDetails?.data?.approved_at

  if (isLoading || isKycDetailsLoading) return <Spinner />

  if (error) {
    return <ErrorFallback />
  }

  return (
    <div>
      <SectionContainer overrideClass="my-0">
        <PageNav>
          <>{data?.data?.event?.name || "View Event"}</>
        </PageNav>
      </SectionContainer>

      {/* Host kyc */}
      <div className="flex flex-col">
        {canApproveKyc && kycDetails && (
          <SectionContainer sectionTitle="Kyc details">
            <EventDetailsStack>
              {!kycDetails?.data?.approved_at && (
                <RtBanner
                  title="Review KYC Details:"
                  description="Please review the host kyc details in order to approve or reject an event."
                  bannerType="warning"
                />
              )}

              <ContentContainer>
                <HostKyc user={kycDetails.data} />
              </ContentContainer>

              <KycActionButtons hostId={hostId} isKycApproved={isKycApproved} />
            </EventDetailsStack>
          </SectionContainer>
        )}

        {/* Event Stats */}
        {canViewStats && (
          <SectionContainer sectionTitle="Event stats">
            <EventDetailsStack>
              {isAdmin() && (
                <AdminTicketActions
                  slug={slug!}
                  permissions={{ canSellTickets, canApproveWithdrawalRequest }}
                />
              )}

              <>
                <RevenueStats revenueStats={data?.data.revenue_stats} />

                <div className="grid grid-cols-2 gap-4">
                  <TicketsStats stat={data?.data.ticket_stats} />
                  <TicketsStats stat={data?.data.scanned_tickets} />
                </div>

                <SalesGraph
                  graphTitle="Ticket sales"
                  entity="Ticket"
                  categories={time}
                  graphSeries={sales}
                />

                <SalesPieChart dataSeries={pieSeries} labels={labels} />

                <div>
                  <RtTitle>Scans</RtTitle>
                  <TicketScans scans={data?.data.ticket_scans} />
                </div>
              </>
            </EventDetailsStack>
          </SectionContainer>
        )}

        {/* Event car & Tickets */}
        <SectionContainer sectionTitle="Event details & Tickets">
          <div>
            {canPublishEvent && (
              <RtBanner
                bannerType="warning"
                title="Draft Event"
                description="Your event is currently in draft status. Please publish it  for our admins to review."
                action={
                  <RtActionButton handleClick={handlePublish} name="Publish" />
                }
              />
            )}

            {isEventRejected && (
              <RtBanner
                bannerType="error"
                title="Rejected"
                description={
                  data.data.event.rejection_reason ||
                  "This event has been rejected by the admin"
                }
              />
            )}

            {data?.data.event?.is_host && (
              <HostEventActions
                slug={slug!}
                event={data?.data.event}
                permissions={{
                  canEdit,
                  canRequestWithdrawal,
                }}
                overrideClass="mt-4"
              />
            )}

            <EventCard
              showEventAbout
              event={data?.data.event}
              canBuyTicket={false}
              isAdmin
              overrideClass="mt-4"
            />

            {isAdmin() && (
              <AdminEventAction
                slug={slug!}
                permissions={{
                  canApproveEvent,
                  canCancelEvent,
                  canMarkEventAsComplete: isEventCompleted,
                }}
                isKycApproved={isKycApproved}
                overrideClass="mt-2"
              />
            )}

            <div className="flex flex-wrap gap-5">
              {data?.data.ticket_groups.map(
                (ticketGroup: TicketGroupInterface, idx: number) => (
                  <PurchaseTicketContainer
                    canEdit={canEdit}
                    ticketGroup={ticketGroup}
                    slug={slug!}
                    key={`my-event-details-ticket-${idx}`}
                    overrideClass="mt-4"
                  />
                ),
              )}
            </div>
          </div>
        </SectionContainer>
      </div>

      <ActionLoadingModal
        isOpen={publishEventQueryClient.isPending}
        loadingText="Please wait as your event is being sent in for a review"
      />
    </div>
  )
}

export default MyEventDetails

const EventDetailsStack = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col gap-4">{children}</div>
}
