import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import removeFalsyValues from "utils/remove-falsy.util"

export function useListWithdrawalRequests(params?: Record<string, any>) {
  return useQuery({
    queryKey: ["/admin/withdrawal-requests", params],
    queryFn: async () =>
      await handleGetRequest(
        "admin/withdrawal-requests",
        removeFalsyValues(params),
      ),
  })
}
