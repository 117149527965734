import { SummaryStatProps } from "types/component-props.types"

function SummaryStat({
  value,
  title,
  iconWrapperColor,
  titleColor,
  valueColor,
  icon,
}: SummaryStatProps) {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`rounded-full ${
          iconWrapperColor || "bg-rt-white"
        } w-10 h-10 flex items-center justify-center`}
      >
        {icon}
      </div>
      <div className="flex flex-col">
        <span
          className={`font-poppins font-light ${titleColor || "text-rt-white"}`}
        >
          {title}
        </span>
        <span className={`font-bold ${valueColor || "text-rt-white"}`}>
          {value}
        </span>
      </div>
    </div>
  )
}

export default SummaryStat
