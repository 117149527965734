interface RTFormHelperTextProps {
  helperText: string
  overrideClass?: string
}

function RTFormHelperText({
  helperText,
  overrideClass,
}: RTFormHelperTextProps) {
  return (
    <p className={`text-[12px] text-rt-dark-blue mb-1 ${overrideClass}`}>
      {helperText}
    </p>
  )
}

export default RTFormHelperText
