import { ADMIN } from "constants/global.constants"
import { getUnixTime } from "date-fns"
import { SalesInterface } from "types/index.types"
import * as XLSX from "xlsx"

function exportToExcel(data: SalesInterface[], userType: string) {
  if (!data || data.length === 0) {
    alert("No data available to export.")
    return
  }
  const date = new Date()
  const formattedData = data.map((sale) => {
    const buyer = userType === ADMIN ? {
      Buyer: sale.buyer!,
    } : {}
    return {
      "Ticket No": sale.ticket_ids.split(",")[0],
      "Ticket Type": sale.ticket_type,
      Qty: sale.total_tickets,
      ...buyer,
      "Cost (Ksh)": sale.price,
      "Total Earned (Ksh)": sale.total_price,
      "Commission (Ksh)": sale.total_commission,
      "Date/Time": sale.sold_at,
    }
  })

  const workbook = XLSX.utils.book_new()

  const worksheet = XLSX.utils.json_to_sheet(formattedData)

  XLSX.utils.book_append_sheet(workbook, worksheet, `Ticket Vipi Sales`)

  XLSX.writeFile(workbook, `sales_report_${getUnixTime(date)}.xlsx`)
}

export default exportToExcel
