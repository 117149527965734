import { RtTitleProps } from "types/component-props.types"
import { cn } from "utils/cn.util"

function RtTitle({ children, overrideClass = "" }: RtTitleProps) {
  return (
    <h2
      className={cn(
        "font-semibold font-raleway text-rt-blue text-xl",
        overrideClass,
      )}
    >
      {children}
    </h2>
  )
}

export default RtTitle
