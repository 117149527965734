import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useResetPassword(handleSuccess: () => void) {
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationFn: async (formData: any) => {
      const response = await handlePostRequest("/password-reset", formData)
      return response
    },
    onSuccess: () => {
      handleSuccess()
      showToaster("success", "Your password has been reset successfully.", 4000)
    },
    onError: (err: any) => {
      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
      showToaster("error", "An error occurred while resetting your password")
    },
    throwOnError: (error) => {
      handleError(error)
      return false
    },
  })

  return mutation
}
