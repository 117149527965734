import privacyPolicyHtml from "../../assets/html/rapidtickethub-privacy.html?raw"
import DOMPurify from "dompurify"

function PrivacyPolicy() {
  const sanitizedHtml = DOMPurify.sanitize(privacyPolicyHtml)
  return (
    <div className="p-10" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  )
}

export default PrivacyPolicy
