import RtButton from "components/button/RtButton"
import { Share2 } from "react-feather"
import { showToaster } from "utils/show-toaster.util"

interface CopyToClipBoardProps {
  copyText: string
  successMsg: string
  overrideClass?: string
}

function CopyToClipBoard({
  copyText,
  successMsg,
  overrideClass,
}: CopyToClipBoardProps) {
  const handleCopyEventLink = () => {
    navigator.clipboard.writeText(copyText)
    showToaster("success", successMsg, 4000)
  }
  return (
    <RtButton
      variant="link"
      overrideClass={overrideClass}
      icon={<Share2 size={15} />}
      onClick={() => handleCopyEventLink()}
    >
      Share
    </RtButton>
  )
}

export default CopyToClipBoard
