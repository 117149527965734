import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useEventBySlug(slug: string) {
  // TODO: Find a better way to handle null/undefined query keys,something similar to swr -> Masho Ado 10/10/2023
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: queryKeyGenerator.eventBySlug(slug),
    queryFn: async () => await handleGetRequest(`events/${slug}/view`),
  })

  return {
    isLoading,
    data,
    error,
    refetch
  }
}
