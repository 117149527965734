import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

interface IUseTabs<T> {
  initialTab: T
  queryKey: string
}

/**
 * Custom hook for managing tabs.
 *
 * @template {enum} T - Type of tab (enum).
 * @param {Object} params - Parameters for the hook.
 * @param {T} params.initialTab - The initial active tab.
 * @param {string} params.queryKey - The query key used for storing the active tab in the URL.
 * @returns {{
 *   activeTab: T, // The currently active tab.
 *   changeTab: (tab: T) => void // Function to change the active tab.
 * }}
 */
function useTabs<T>({ initialTab, queryKey }: IUseTabs<T>): {
  activeTab: T
  changeTab: (tab: T) => void
} {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeTab = searchParams.get(queryKey) ?? initialTab

  const changeTab = (tab: T) => {
    if (queryKey) {
      setSearchParams((prevSearchParams) => ({
        ...prevSearchParams,
        [queryKey]: tab,
      }))
    }
  }

  useEffect(
    function setInitialTab() {
      const currentTab = searchParams.get(queryKey)

      if (!currentTab) {
        setSearchParams((prevSearchParams) => ({
          ...prevSearchParams,
          [queryKey]: initialTab,
        }))
      }
    },
    [initialTab, queryKey],
  )
      

  return { activeTab: activeTab as T, changeTab }
}

export default useTabs
