import { ADMIN } from "constants/global.constants";
import { getUnixTime } from "date-fns";
import { SalesInterface } from "types/index.types";

function exportToCSV(data: SalesInterface[], userType: string) {
  if (!data || data.length === 0) {
    alert("No data available to export.");
    return;
  }
  const date = new Date();

  const formattedData = data.map((sale) => {
    const buyer = userType === ADMIN
      ? {
          Buyer: sale.buyer!,
        }
      : {};
    return {
      "Ticket No": sale.ticket_ids.split(",")[0],
      "Ticket Type": sale.ticket_type,
      Qty: sale.total_tickets,
      ...buyer,
      "Cost (Ksh)": sale.price,
      "Total Earned (Ksh)": sale.total_price,
      "Commission (Ksh)": sale.total_commission,
      "Date/Time": sale.sold_at,
    };
  });

  const csvContent =
    [
      Object.keys(formattedData[0]).join(","),
      ...formattedData.map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",")
      ),
    ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const fileName = `sales_report_${getUnixTime(date)}.csv`;

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default exportToCSV;
