import ReactDatePicker from "react-datepicker"
import "../../assets/styles/datepicker-wrapper.scss"
import { RTDatePickerProps } from "types/component-props.types"

function RTDatePicker({
  selectedDate,
  handleChange,
  dateFormat,
  placeholderText,
  showTimeSelect,
}: RTDatePickerProps) {
  return (
    <ReactDatePicker
      className="react-datepicker-wrapper "
      selected={selectedDate}
      onChange={handleChange}
      showTimeSelect={!!showTimeSelect}
      dateFormat={dateFormat || "d MMM yyyy h:mm aa"}
      placeholderText={placeholderText || "Select date"}
    />
  )
}

export default RTDatePicker
