import RtButton from "components/button/RtButton"
import UpdateTicketGroupModal from "components/modal/UpdateTicketGroupModal"
import PurchaseTicket from "components/ticket/PurchaseTicket"
import { useState } from "react"
import { Edit } from "react-feather"
import { TicketGroupInterface } from "types/index.types"
import { cn } from "utils/cn.util"

interface PurchaseTicketContainerProps {
  canEdit: boolean
  ticketGroup: TicketGroupInterface
  slug: string
  overrideClass?: string
}

function PurchaseTicketContainer({
  canEdit,
  ticketGroup,
  slug,
  overrideClass,
}: PurchaseTicketContainerProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen)
  return (
    <div
      className={cn(
        "flex flex-col  gap-2 flex-1 sm:flex-initial",
        overrideClass,
      )}
    >
      {canEdit && (
        <div className="flex justify-end flex-1">
          <RtButton
            variant="link"
            icon={<Edit size={15} />}
            onClick={toggleEditModal}
          >
            Edit Ticket
          </RtButton>

          <UpdateTicketGroupModal
            isOpen={isEditModalOpen}
            toggleModal={toggleEditModal}
            slug={slug!}
            ticketGroup={ticketGroup}
          />
        </div>
      )}
      <PurchaseTicket ticketGroup={ticketGroup} canOpenCart={() => false} />
    </div>
  )
}

export default PurchaseTicketContainer
