import { useEffect } from "react"

function ScrollToTop({ children }: { children: React.ReactNode }) {
  const { pathname } = window.location

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{children}</>
}

export default ScrollToTop
