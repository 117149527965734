import { ON_GOING } from "constants/global.constants"
import { TicketGroupInterface } from "types/index.types"

export function canProceedToCheckout(
  ticketGroups: Array<TicketGroupInterface>,
) {
  if (!ticketGroups) return false

  return ticketGroups.some((ticket) => ticket.status === ON_GOING)
}
