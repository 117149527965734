import { useEffect, useState } from "react"

/**
 * Custom hook that debounces the given value.
 *
 * @param value - The value to be debounced.
 * @param time - The debounce time in milliseconds (default: 1000ms).
 * @returns The debounced value.
 */
function useDebounce(value: any, time: number = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, time)

    // Clear the timeout on unmount or when the `value` prop changes.
    return () => clearTimeout(timeoutId)
  }, [value, time])

  return debouncedValue
}

export default useDebounce
