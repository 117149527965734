import { twMerge } from "tailwind-merge"
import { clsx, ClassValue } from "clsx"

/**
 * Combines multiple class names into a single string.
 * 
 * @param classNames - The class names to combine.
 * @returns The combined class names as a string.
 */
export function cn(...classNames: ClassValue[]) {
  return twMerge(clsx(...classNames))
}
