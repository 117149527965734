import useTabs from "hooks/useTabs"
import RtTab from "./RtTab"
import RtTabLayout from "./RtTabLayout"

interface RtTabsProps<T> {
  overrideTabLayoutClass?: string
  children?: React.ReactNode
  tabs: T[]
  disabledTabs?: T[]
  hiddenTabs?: T[]
  initialTab: T
  queryKey?: string
  disabledReason?: string
}

function RtTabs<T extends string>({
  overrideTabLayoutClass,
  tabs,
  initialTab,
  queryKey = "tab",
  hiddenTabs,
  disabledTabs,
  disabledReason,
}: RtTabsProps<T>) {
  const { activeTab, changeTab } = useTabs({
    initialTab,
    queryKey,
  })

  const filteredTabs =
    hiddenTabs && hiddenTabs.length > 0
      ? tabs.filter((tab) => !hiddenTabs?.includes(tab))
      : tabs

  return (
    <RtTabLayout overrideClass={overrideTabLayoutClass}>
      {filteredTabs.map((tab) => (
        <RtTab
          disabled={!!disabledTabs?.includes(tab)}
          disabledReason={disabledReason}
          key={tab}
          tab={tab}
          handleChangeTab={changeTab}
          activeTab={activeTab}
        >
          {tab}
        </RtTab>
      ))}
    </RtTabLayout>
  )
}

export default RtTabs
