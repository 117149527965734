import LoaderContainer from "components/loader/LoaderContainer"
import "./assets/styles/global.scss"
import Routes from "./routes"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Toaster } from "react-hot-toast"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "redux/store"
import ScrollToTop from "components/scroll-top"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ErrorBoundary from "components/error-boundary"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ScrollToTop>
            <LoaderContainer>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
              <Toaster />
            </LoaderContainer>
          </ScrollToTop>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </QueryClientProvider>
  )
}

export default App
