import { ContentContainerProps } from "types/component-props.types"
import { cn } from "utils/cn.util"

function ContentContainer({ children, overrideClass }: ContentContainerProps) {
  return (
    <div
      className={cn(
        "py-6 px-3 rounded-lg bg-white border-[0.1rem] border-rt-light-blue",
        overrideClass,
      )}
    >
      {children}
    </div>
  )
}

export default ContentContainer
