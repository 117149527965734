import { PERIODS, TPeriodValue } from "constants/global.constants"
import { formatDateToDisplay, getDateFromUnix } from "./date-formatter.util"

export default function getPeriodDisplay(periodKey: TPeriodValue) {
  if (periodKey.startsWith("custom")) {
    // periodKey -> custom:1717275600,1719262800
    const [startDate, endDate] = periodKey.split(":")[1].split(",")

    const formattedStartDate = formatDateToDisplay(
      getDateFromUnix(+startDate),
      "PP",
    )
    const formattedEndDate = formatDateToDisplay(getDateFromUnix(+endDate))

    return `${formattedStartDate} - ${formattedEndDate}`
  }
  const periodsArray = Object.values(PERIODS)
  const period = periodsArray.find((period) => period.value === periodKey)

  if (period) {
    return period.label
  }
}
