import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"

interface SalesGraphProps {
  categories: string[]
  graphSeries: number[]
  graphTitle: string
  entity: string
}

function SalesGraph({
  categories,
  graphSeries,
  graphTitle,
  entity,
}: SalesGraphProps) {
  const series = [
    {
      name: entity,
      data: graphSeries || [],
    },
  ]

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#c702e4"],
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 4,
      colors: ["#c702e4"],
      strokeWidth: 2,
      hover: {
        size: 5,
      },
    },
    title: {
      text: graphTitle,
      align: "left",
      style: {
        color: "#0158b8",
        fontWeight: "600",
      },
    },
    grid: {
      show: true,
      borderColor: "#ddd",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Tickets Sold",
      },
    },
    colors: ["#c702e4"],
  }

  return (
    <div id="chart" className="bg-rt-white">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  )
}

export default SalesGraph
