import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js"

interface TValidatePhoneNumber {
  phoneNumber: string
  countryCode: CountryCode
  errorMessage?: string
}

export function validatePhoneNumber({
  phoneNumber,
  countryCode,
}: TValidatePhoneNumber) {
  return isValidPhoneNumber(phoneNumber, countryCode)
}

export function formatPhoneNumber({
  phoneNumber,
  countryCode,
}: TValidatePhoneNumber) {
  const phoneWithoutSpaces = phoneNumber.replace(/\s/g, "")

  const formattedPhoneNumber = parsePhoneNumber(phoneWithoutSpaces, countryCode)

  if (!formattedPhoneNumber) {
    return phoneNumber
  }

  return formattedPhoneNumber.formatInternational().replace(/\s/g, "")
}
