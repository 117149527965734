import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ModalWrapperProps } from "types/component-props.types"
import BaseModal from "./BaseModal"
import { Edit, LogOut, Save, X } from "react-feather"
import RtAvatar from "components/avatar"
import { useLogout } from "hooks/api/useLogout"
import { useEditProfile } from "hooks/api/useEditProfile"
import RtInput from "components/input/RtInput"
import { clearCart } from "redux/features/cart"
import { showToaster } from "utils/show-toaster.util"



type ProfileData = {
  [key: string]: string // Allow dynamic keys with string values
}

function UserProfileModal({ isOpen, toggleModal }: ModalWrapperProps) {
  const user = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [profileData, setProfileData] = useState<ProfileData>({
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
  })

  const [initialData, setInitialData] = useState<ProfileData>({
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
  })

  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [errors, setErrors] = useState<ProfileData>({
    name: "",
    email: "",
    phone_number: "",
  })

  const queryClient = useLogout(toggleModal)

  const editProfile = useEditProfile({
    onSuccess: () => {
      setIsEditing(false)
      setUnsavedChanges(false) // Reset unsaved changes after saving
      if (toggleModal) toggleModal()
    },
  })

  useEffect(() => {
    if (isOpen) {
      setInitialData({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
      })
      setProfileData({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
      })
      setUnsavedChanges(false)
    }
  }, [isOpen, user])

  const validateFields = (): boolean => {
    let isValid = true
    const newErrors: ProfileData = { name: "", email: "", phone_number: "" }

    // Name validation
    if (!/^[a-zA-Z\s]+$/.test(profileData.name)) {
      newErrors.name = "Name should contain only letters and spaces"
      isValid = false
    }

    // Updated email validation with stricter domain requirements
    if (
      !/^[\w-]+@([\w-]+\.)+(?:com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)$/i.test(
        profileData.email,
      )
    ) {
      newErrors.email = "Invalid email format or domain"
      isValid = false
    }

    // Updated phone number validation (12-digit international format)
    if (!/^\+?[1-9][0-9]{11}$/.test(profileData.phone_number)) {
      newErrors.phone_number =
        "Phone number must be in international format (e.g., +254123456789) and must contain exactly 12 digits"
      isValid = false
    }

    setErrors(newErrors)
    console.log("Validation Results:", { newErrors, isValid })
    return isValid
  }

  const handleChange = (updatedField: { [key: string]: string }) => {
    const updatedProfileData = { ...profileData, ...updatedField }
    setProfileData(updatedProfileData)
    setUnsavedChanges(
      JSON.stringify(updatedProfileData) !== JSON.stringify(initialData),
    )
  }

  const handleSave = () => {
    console.log("Saving Profile Data:", profileData)

    if (!validateFields()) {
      console.warn("Validation failed. Save operation blocked.")
      return
    }

    const changedFields = Object.keys(profileData).reduce((acc, key) => {
      if (profileData[key] !== initialData[key]) {
        acc[key] = profileData[key]
      }
      return acc
    }, {} as ProfileData)

    if (Object.keys(changedFields).length === 0) {
      showToaster("success", "No changes to save")
      return
    }

    console.log("Validated and Changed Fields:", changedFields)
    editProfile.mutate(changedFields)
    setUnsavedChanges(false)
  }

  const handleLogout = () => {
    queryClient.mutate()
    dispatch(clearCart())
  }

  const handleClose = () => {
    if (unsavedChanges) {
      showToaster("error", "Please save or discard your changes first")
      return
    }
    if (toggleModal) toggleModal()
  }

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose}>
      <div className="flex justify-end mb-4">
        <button
          onClick={handleClose}
          className="text-rt-gray hover:text-rt-red transition-colors"
          aria-label="Close modal"
        >
          <X size={20} />
        </button>
      </div>

      <div className="flex flex-col items-center mb-2 w-full max-w-md mx-auto">
        <RtAvatar name={profileData.name} />
        <div className="mt-4 flex flex-col gap-2 text-center w-full">
          {isEditing ? (
            <>
              <RtInput
                label="Name"
                inputName="name"
                inputValue={profileData.name}
                onInputChange={handleChange}
                errorMessage={errors.name}
              />
              <RtInput
                label="Email"
                inputName="email"
                inputValue={profileData.email}
                onInputChange={handleChange}
                inputType="email"
                errorMessage={errors.email}
              />
              <RtInput
                label="Phone Number"
                inputName="phone_number"
                inputValue={profileData.phone_number}
                onInputChange={handleChange}
                inputType="tel"
                errorMessage={errors.phone_number}
              />
            </>
          ) : (
            <>
              <p className="text-lg font-medium">{profileData.name}</p>
              <span className="text-sm text-rt-gray break-all">{profileData.email}</span>
              <span className="text-sm text-rt-gray">{profileData.phone_number}</span>
            </>
          )}
        </div>
      </div>

      <div className="mt-4 flex justify-between border-t pt-2 px-12">
        {isEditing ? (
          <>
            <button
              className="flex items-center gap-1 h-[20px] hover:opacity-80 transition-opacity"
              style={{ color: '#6B7280' }}
              onClick={() => {
                setIsEditing(false)
                setProfileData(initialData)
                setErrors({ name: "", email: "", phone_number: "" })
              }}
            >
              <span>Cancel</span>
            </button>
            <button 
              className="flex items-center gap-1 h-[20px] hover:opacity-80 transition-opacity"
              style={{ color: '#c702e4' }}
              onClick={handleSave}
            >
              <Save size={15} className="relative top-[1px]" />
              <span>Save</span>
            </button>
          </>
        ) : (
          <>
            <button className="text-rt-blue" onClick={() => setIsEditing(true)}>
              <Edit size={15} /> Edit Profile
            </button>
            <button className="text-rt-red" onClick={handleLogout}>
              <LogOut size={15} /> Log out
            </button>
          </>
        )}
      </div>
    </BaseModal>
  )
}

export default UserProfileModal
