import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { useDispatch } from "react-redux"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

function useAddKyc() {
  const dispatch = useDispatch()
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationKey: ["addKyc"],
    mutationFn: async (data: FormData) => {
      await handlePostRequest(`/host_kyc`, data)
    },
    onSuccess: () => {
      showToaster("success", "You have successfully added kyc details", 5000)
      dispatch(clearAllValidationErrors())
    },
    onError: (err: any) => {
      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
    },
  })

  return mutation
}

export default useAddKyc
