import { AnimatePresence } from "framer-motion"
import { BaseModalProps } from "types/component-props.types"
import { motion } from "framer-motion"
function BaseModal({
  isOpen,
  onClose,
  children,
  overrideClass,
}: BaseModalProps) {
  if (!isOpen) return null

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 backdrop-filter backdrop-blur-sm"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={onClose}
          ></div>

          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring", duration: 0.7 }}
            exit={{ opacity: 0 }}
          >
            <div
              className={`relative z-10 bg-white w-11/12  min-w-80  md:min-w-[500px] max-w-lg max-h-60vh rounded-lg mx-auto shadow-sm p-4 ${overrideClass} `}
            >
              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default BaseModal
