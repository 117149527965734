import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"
import { showToaster } from "utils/show-toaster.util"

export function useCancelEvent(slug: string, payload: { [key: string]: any }) {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async () =>
      handlePostRequest(`/admin/events/${slug}/cancel`, payload),
    onSuccess: () => {
      showToaster(
        "success",
        "The event has successfully been canceled.",

        5000,
      )
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(slug),
      })
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}
