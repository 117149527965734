import { ModalWrapperProps } from "types/component-props.types"
import BaseModal from "./BaseModal"
import Spinner from "components/loader/Spinner"

function ActionLoadingModal({
  toggleModal,
  isOpen,
  loadingText,
}: ModalWrapperProps) {
  const handleClose = () => {
    toggleModal && toggleModal()
  }

  if (!isOpen) return null
  
  return (
    <BaseModal isOpen={isOpen} onClose={handleClose}>
      <div className="flex flex-col justify-center items-center">
        <Spinner />
        <p className="text-rt-dark-blue font-poppins mt-4 text-center">
          {loadingText || "Loading..."}
        </p>
      </div>
    </BaseModal>
  )
}

export default ActionLoadingModal
