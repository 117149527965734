import { useEffect, useState } from "react"
import { CountDownProps } from "types/component-props.types"
import "assets/styles/stroke.scss"

function Counter({
  overrideClass,
  timeInSeconds,
  children,
  cbWhenTimeExpires,
  setTimerReset,
  timerReset,
}: CountDownProps) {
  const initialMinutes = Math.floor(timeInSeconds / 60)
  const initialSeconds = timeInSeconds % 60

  const [remainingMinutes, setRemainingMinutes] = useState(initialMinutes)
  const [seconds, setSeconds] = useState(initialSeconds)
  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingMinutes === 0 && seconds <= 0 && !timerReset) {
        clearInterval(timer)
        cbWhenTimeExpires?.()
        setTimerReset?.(true)
      } else if (remainingMinutes !== 0 && seconds === 0) {
        setRemainingMinutes((prevMinutes) => prevMinutes - 1)
        setSeconds(59)
      } else if (remainingMinutes >= 0 && seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [remainingMinutes, seconds, timerReset])

  if (remainingMinutes === 0 && seconds === 0) {
    return (
      <>
        <div
          className={` border-2 border-red-500 bg-white   py-3 rounded-md flex justify-center items-center ${overrideClass}`}
        >
          <h1 className="counter-text">EXPIRED</h1>
        </div>
        <p className="text-rt-red">Your time has expired.</p>
      </>
    )
  }

  return (
    <>
      <div className=" px-3 py-1 text-sm leading-6 text-gray-600 italic">
        Please complete your ticket purchase before the timer expires.
      </div>

      <div
        className={`bg-rt-blue text-rt-white py-3 rounded-md flex justify-center items-center ${overrideClass}`}
      >
        {`${
          remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes
        }:${seconds < 10 ? `0${seconds}` : seconds}`}
      </div>

      {children}
    </>
  )
}

export default Counter
