import { useSearchParams } from "react-router-dom"

/**
 * Interface for URL parameters.
 * @interface
 * @property {string} key - The key of the parameter.
 * @property {string} value - The value of the parameter.
 */
interface ParamProps {
  [key: string]: string
}

/**
 * Custom hook for setting URL parameters.
 * @function
 * @returns {Object} An object containing the current search parameters and a function to set new parameters.
 * @example
 * const { searchParams, handleSetUrlParams } = useRtSetUrlParams();
 * handleSetUrlParams({ key1: 'value1', key2: 'value2' });
 */
function useRtSetUrlParams() {
  let [searchParams, setSearchParams] = useSearchParams()

  /**
   * Function to set new URL parameters.
   * @param {ParamProps} params - The new parameters to set.
   */
  const handleSetUrlParams = (params: ParamProps) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)

      for (const key in params) {
        if (
          params[key] === undefined ||
          params[key] === null ||
          params[key] === ""
        ) {
          // Remove the parameter if the value is falsy
          newSearchParams.delete(key)
        } else {
          newSearchParams.set(key, params[key])
        }
      }

      return newSearchParams.toString()
    })
  }

  return { searchParams, handleSetUrlParams }
}

export default useRtSetUrlParams


