import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"

export function useListCategories() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["listCategories"],
    queryFn: async () => await handleGetRequest("/categories"),
  })

  return {
    isLoading,
    data,
    error,
  }
}
