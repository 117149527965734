import RtButton from "components/button/RtButton"
import RtInput from "components/input/RtInput"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import PageNav from "components/nav/PageNav"
import CategorySearchSelect from "components/search-select/CategorySearchSelect"
import RtTitle from "components/title/RtTitle"
import RtFileUploader from "components/file-uploader/RtFileUploader"
import LocationSearchSelect from "components/search-select/LocationSearchSelect"
import RtTextArea from "components/input/RtTextArea"
import RTDatePicker from "components/datepicker/RTDatepicker"
import RtLabel from "components/input/RtLabel"
import { ICategory, ILocation } from "types/index.types"
import RTError from "components/input/RTError"
import RTFormHelperText from "components/input/RTFormHelperText"
import { useState } from "react"

interface AddEventDetailsProps {
  handleNextTab: () => void
  formData: {
    name: string
    start_date: Date | null
    end_date: Date | null
    description: string
    category: ICategory | undefined
    place: ILocation | undefined
    images: Blob[] | null
  }
  handleChange: (value: { [key: string]: any }) => void
}

function AddEventDetails({
  handleNextTab,
  formData,
  handleChange,
}: AddEventDetailsProps) {
  const [imageURLs, setImageURLs] = useState(() => {
    if (!formData?.images) return []

    return formData.images.map((image) => {
      if (typeof image === "string") return image
      if (image instanceof File) {
        return URL.createObjectURL(image)
      }
      return ""
    })
  })

  return (
    <div>
      <div className="lg:flex">
        <SectionContainer overrideClass="flex-1">
          <PageNav>Create Event</PageNav>
          <RtTitle>Event Info</RtTitle>

          <ContentContainer overrideClass="grid grid-cols-1 gap-4">
            <RtInput
              inputName="name"
              inputType="text"
              inputPlaceholder="Career Fair: Exclusive Hiring"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              label="Event Name"
              inputValue={formData?.name || ""}
              onInputChange={handleChange}
              isRequired
            />
            <RtTextArea
              inputName="description"
              inputPlaceholder="Let's have fun"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              label="Description"
              inputValue={formData?.description || ""}
              onInputChange={handleChange}
              isRequired
              formHelperText="Provide a description of this event."
            />

            <div>
              <RtLabel label="Location" isRequired />
              <RTFormHelperText helperText="Select the appropriate location for your event" />

              <LocationSearchSelect
                selectedLocation={formData?.place}
                handleSelected={(selectedOption) =>
                  handleChange({ place: selectedOption || undefined })
                }
              />
              <RTError inputName="place_id" />
            </div>

            <div>
              <RtLabel label="Category" isRequired />

              <CategorySearchSelect
                selectedCategory={formData?.category}
                handleSelected={(selectedOption) =>
                  handleChange({ category: selectedOption || undefined })
                }
              />
              <RTError inputName="category_id" />
            </div>

            <div>
              <RtLabel label=" Start Date & Time" isRequired />
              <RTFormHelperText helperText="Select the date and time when the event starts" />

              <RTDatePicker
                selectedDate={formData?.start_date}
                handleChange={(date: Date | null) =>
                  handleChange({ start_date: date })
                }
                placeholderText="Select start date & time"
                showTimeSelect
              />
              <RTError inputName="start_date" />
            </div>

            <div>
              <RtLabel label=" End Date & Time" isRequired />
              <RTFormHelperText helperText="Select the date and time when the event ends" />

              <RTDatePicker
                selectedDate={formData?.end_date}
                handleChange={(date: Date | null) =>
                  handleChange({ end_date: date })
                }
                placeholderText="Select end date & time"
                showTimeSelect
              />
              <RTError inputName="end_date" />
            </div>
          </ContentContainer>
        </SectionContainer>

        <SectionContainer overrideClass="flex-1 lg:flex lg:flex-col justify-between">
          <div>
            <RtTitle>Upload Image</RtTitle>
            <RTFormHelperText helperText="Upload the event image" />

            <RtFileUploader
              setImageUrls={setImageURLs}
              defaultFileUrls={imageURLs?.length > 0 ? imageURLs : []}
              handleFiles={(files) => handleChange({ images: files })}
              shouldResize
              maxFile={5}
            />
          </div>
          <RTError inputName="image" />

          <RtButton onClick={handleNextTab} overrideClass="w-full mt-8">
            ADD TICKET GROUP
          </RtButton>
        </SectionContainer>
      </div>
    </div>
  )
}

export default AddEventDetails
