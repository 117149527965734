import { useMutation } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"
import successAudio from "../../assets/audio/success-sound.mp3"
import errorAudio from "../../assets/audio/error-sound.mp3"

function useScanTicket() {
  const mutation = useMutation({
    mutationFn: async (qrCodeText: string) => {
      await handlePostRequest("/scan-ticket", {
        qrcode_text: qrCodeText,
      })
    },
    onSuccess: () => {
      const audio = new Audio(successAudio)
      audio.play()
      showToaster("success", "Ticket successfully scanned", 4000)
    },
    onError: (error) => {
      const audio = new Audio(errorAudio)
      audio.play()
      handleError(error)
    },
  })
  return mutation
}

export default useScanTicket
