import RtTitle from "components/title/RtTitle"
import { cn } from "utils/cn.util"
import { ReactNode } from "react"

interface SectionContainerProps {
  children?: ReactNode
  overrideClass?: string
  overrideTitleClass?: string
  sectionTitle?: string
}

function SectionContainer({
  children,
  overrideClass = "",
  sectionTitle,
}: SectionContainerProps) {
  return (
    <section className={cn("my-5 mx-5 lg:mx-32", overrideClass)}>
      {sectionTitle && <RtTitle overrideClass={`mb-3`}>{sectionTitle}</RtTitle>}
      {children}
    </section>
  )
}

export default SectionContainer
