import { ChevronLeft, ChevronRight } from "react-feather"

interface PaginationInterface {
  current_page: number
  first_page: number
  last_page: number
}

interface PaginationProps {
  meta: PaginationInterface
  handlePageChange: (page: number) => void
}

function Pagination({ meta, handlePageChange }: PaginationProps) {
  const { current_page, last_page, first_page } = meta

  const handleNextPage = () => {
    if (current_page === last_page) return current_page

    const newPage = current_page + 1

    handlePageChange(newPage)
  }

  const handlePreviousPage = () => {
    if (current_page === first_page) return current_page

    const newPage = current_page - 1

    handlePageChange(newPage)
  }

  return (
    <div className="flex items-center gap-4">
      <div className="border border-rt-blue p-1 rounded ">
        <ChevronLeft
          className=" text-rt-blue "
          size={24}
          strokeWidth={2}
          onClick={handlePreviousPage}
        />
      </div>

      <span className="font-semibold text-rt-blue">{`${current_page} / ${last_page}`}</span>

      <div className="border border-rt-blue p-1 rounded ">
        <ChevronRight
          className=" text-rt-blue "
          size={24}
          strokeWidth={2}
          onClick={handleNextPage}
        />
      </div>
    </div>
  )
}

export default Pagination
