import React, { useState } from "react"
import RtInput from "./RtInput"
import { Eye, EyeOff, Lock } from "react-feather"

interface RTPasswordProps {
  inputName?: string
  inputPlaceholder?: string
  handleInputChange: (value: { [key: string]: string }) => void
  handleEnterKey?: (e: React.KeyboardEvent) => void
  label?: string
}

function RTPassword({
  inputName = "password",
  inputPlaceholder = "Enter password",
  handleInputChange,
  handleEnterKey,
  label,
}: RTPasswordProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const onViewPassword = () => setIsPasswordVisible(!isPasswordVisible)
  return (
    <RtInput
      label={label}
      inputName={inputName}
      inputType={isPasswordVisible ? "text" : "password"}
      inputPlaceholder={inputPlaceholder}
      inputOverrideClass="bg-rt-white bg-opacity-100"
      onInputChange={handleInputChange}
      isRequired
      onKeyUp={handleEnterKey}
      icon={<Lock className="text-rt-blue" size={16} strokeWidth={1} />}
      endIcon={
        isPasswordVisible ? (
          <EyeOff
            className="text-rt-blue"
            size={16}
            strokeWidth={1}
            onClick={onViewPassword}
          />
        ) : (
          <Eye
            className="text-rt-blue"
            size={16}
            strokeWidth={1}
            onClick={onViewPassword}
          />
        )
      }
    />
  )
}

export default RTPassword
