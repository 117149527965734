import { CURRENT_TAB } from "constants/global.constants"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import { handleGetRequest } from "utils/api-handler.util"
import { useInfiniteQuery } from "@tanstack/react-query"


export function useListMyTickets() {
  const { searchParams } = useRtSetUrlParams()

  const activeTab = searchParams.get("tab")

  const isActive =
    activeTab?.toLocaleLowerCase() === CURRENT_TAB.toLocaleLowerCase() ? 1 : 0

  return useInfiniteQuery({
    queryKey: ["listMyTickets", { is_active: isActive }],
    queryFn: async ({ pageParam }) =>
      await handleGetRequest("/tickets/mine", { is_active: isActive, ticketPage: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.meta.current_page
      const lastPage_ = lastPage.data.meta.last_page

      const hasNextPage = currentPage < lastPage_

      return hasNextPage ? currentPage + 1 : undefined
    },
  })

}
