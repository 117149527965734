import React, { AnchorHTMLAttributes } from "react"
import { NavLink as ReactRouterNavLink, useLocation } from "react-router-dom"
import { cva, VariantProps } from "class-variance-authority"
import { cn } from "utils/cn.util"

const navStyles = cva("font-extralight", {
  variants: {
    variant: {
      active: "font-bold border-b-2 border-rt-blue text-rt-blue",
      inactive: "text-rt-gray",
    },
  },
  defaultVariants: {
    variant: "inactive",
  },
})

interface NavLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof navStyles> {
  to: string
  matchWith: string
  display: string
  overrideStyles?: string
}

const RTNavLink: React.FC<NavLinkProps> = ({
  to,
  matchWith,
  display,
  overrideStyles,
}) => {
  const location = useLocation()
  const urlPathStart = location.pathname.split("/")?.[1] || "/"
  const isActive = urlPathStart === matchWith


  const variant = isActive ? "active" : "inactive"

  return (
    <ReactRouterNavLink
      to={to}
      className={cn(navStyles({ variant, className: overrideStyles }))}
    >
      {display}
    </ReactRouterNavLink>
  )
}

export { RTNavLink, navStyles }
