import { cva, VariantProps } from "class-variance-authority"
import React from "react"
import { RtButtonProps } from "types/component-props.types"
import { cn } from "utils/cn.util"

const rtButtonStyles = cva(
  "inline-flex px-4 py-2 text-sm flex-row justify-center items-center rounded-md transform transition-transform duration-200 hover:scale-105",
  {
    variants: {
      variant: {
        solid:
          "bg-gradient-to-r from-purple-600 from-10% to-rt-violet to-90% text-rt-white ",
        outline: "bg-rt-white border border-rt-violet text-rt-violet ",
        link: "rounded-none py-0 px-0  text-rt-violet hover:bg-violet-100",
      },
    },
    defaultVariants: {
      variant: "solid",
    },
  },
)

interface ExtendedRtButtonProps
  extends RtButtonProps,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof rtButtonStyles> {}

function RtButton({
  variant,
  overrideClass,
  textOverrideClass,
  children,
  icon,
  disabled,
  // handleClick,
  onClick,
  ...restProps
}: ExtendedRtButtonProps) {
  return (
    <button
      className={cn(rtButtonStyles({ variant, className: overrideClass }))}
      onClick={onClick}
      disabled={!!disabled}
      {...restProps}
    >
      {icon || null}
      <span
        className={cn(
          "font-poppins font-semibold capitalize ",
          textOverrideClass,
          icon ? "ml-2" : "",
        )}
      >
        {children}
      </span>
    </button>
  )
}

export default RtButton
