import RtButton from "components/button/RtButton"
import RtInput from "components/input/RtInput"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import PageNav from "components/nav/PageNav"
import CategorySearchSelect from "components/search-select/CategorySearchSelect"
import RtTitle from "components/title/RtTitle"
import { useEffect, useState } from "react"
import RtFileUploader from "components/file-uploader/RtFileUploader"
import LocationSearchSelect from "components/search-select/LocationSearchSelect"
import RtTextArea from "components/input/RtTextArea"
import RTDatePicker from "components/datepicker/RTDatepicker"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import RtLabel from "components/input/RtLabel"
import { useLocation, useNavigate } from "react-router-dom"
import { useUpdateEvent } from "hooks/api/useUpdateEvent"
import { formatStartEndDateToIso } from "utils/date-formatter.util"
import ImageCarousel from "components/image-display/image-carousel"

interface UpdateEventProps {
  name: string
  start_date: Date | null
  end_date: Date | null
  description: string
  category: {
    name: string
    id: number | null
  }
  place: {
    description: string
    place_id: string | null
  }
  images: string[]
}

interface UpdateEventInterface {
  slug: string
  name: string
  start_date: string
  end_date: string
  location_name: string
  rejection_reason: null | string
  category_name: string
  host_name: string
  description: string
  currency_code: string
  status: number
  ticket_price: number
  price: string
  image_urls: string
  status_text: string
  state: number
  state_text: string
}

function UpdateEvent() {
  const navigate = useNavigate()
  const state = useLocation().state.event as UpdateEventInterface

  const [formValues, setFormValues] = useState<UpdateEventProps>({
    name: "",
    start_date: null,
    end_date: null,
    description: "",
    category: {
      name: "",
      id: null,
    },
    place: {
      description: "",
      place_id: "",
    },
    images: [],
  })

  const [imageURLs, setImageURLs] = useState(() => {
    if (!formValues?.images) return []

    return formValues.images.map((image: any) => {
      if (typeof image === "string") return image
      if (image instanceof File) {
        return URL.createObjectURL(image)
      }
      return ""
    })
  })

  const handleInputChange = (value: { [key: string]: any }) => {
    setFormValues((prev) => ({ ...prev, ...value }))
  }

  const handleOnSuccess = () => {
    navigate(`/my-events/${state.slug}`)
  }
  const updateEventQueryClient = useUpdateEvent({
    slug: state.slug,
    handleOnSuccess,
  })

  const handleUpdateEvent = async () => {
    const { start_date, end_date } = formatStartEndDateToIso(
      formValues.start_date,
      formValues.end_date,
    )
    const category_id = formValues?.category.id
    const place_id = formValues?.place.place_id

    const formData = new FormData()

    formData.append("name", formValues.name)
    formData.append("description", formValues.description)
    formData.append("start_date", start_date)
    formData.append("end_date", end_date)
    formData.append("category_id", category_id?.toString() || "")
    formData.append("place_id", place_id || "")
    if (formValues.images) {
      for (let i = 0; i < formValues.images.length; i++) {
        const file = formValues.images[i]
        formData.append("images[]", file)
      }
    }

    updateEventQueryClient.mutate(formData)
  }

  // set default form values
  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      name: state.name,
      start_date: new Date(state.start_date),
      end_date: new Date(state.end_date),
      description: state.description,
      place: { description: state.location_name, place_id: null },
      category: { name: state.category_name, id: null },
      images: [],
    }))
  }, [state])

  return (
    <div>
      <SectionContainer>
        <PageNav>Update Event</PageNav>
      </SectionContainer>

      <div className="flex laptop:flex-row flex-col laptop:items-center items-stretch">
        <SectionContainer overrideClass="flex-1">
          <RtTitle>Event Info</RtTitle>

          <ContentContainer>
            <RtInput
              inputValue={formValues.name}
              inputName="name"
              inputType="text"
              inputPlaceholder="Career Fair: Exclusive Hiring"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              inputContainerOverrideClass="mb-4"
              label="Event Name"
              onInputChange={handleInputChange}
              isRequired
            />
            <RtTextArea
              inputValue={formValues.description}
              inputName="description"
              inputPlaceholder="Let's have fun"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              inputContainerOverrideClass="mb-4"
              label="Description"
              onInputChange={handleInputChange}
              isRequired
            />

            <div className="mt-4">
              <RtLabel label="Location" isRequired />
              <LocationSearchSelect
                selectedLocation={formValues.place}
                handleSelected={(selectedOption) =>
                  handleInputChange({
                    place_id: selectedOption?.place_id || "",
                  })
                }
              />
            </div>

            <div className="mt-4">
              <RtLabel label="Category" isRequired />

              <CategorySearchSelect
                selectedCategory={formValues.category}
                handleSelected={(selectedOption) =>
                  handleInputChange({ category_id: selectedOption?.id || "" })
                }
              />
            </div>

            <div className="mt-4">
              <RtLabel label=" Start Date & Time" isRequired />

              <RTDatePicker
                selectedDate={formValues?.start_date}
                handleChange={(date: Date | null) =>
                  handleInputChange({ start_date: date })
                }
                placeholderText="Select start date & time"
                showTimeSelect
              />
            </div>

            <div className="mt-4">
              <RtLabel label=" End Date & Time" isRequired />

              <RTDatePicker
                selectedDate={formValues?.end_date}
                handleChange={(date: Date | null) =>
                  handleInputChange({ end_date: date })
                }
                placeholderText="Select end date & time"
                showTimeSelect
              />
            </div>
          </ContentContainer>
        </SectionContainer>

        <div className="flex-1">
          <SectionContainer overrideClass="">
            <div>
              <RtLabel label="To change these images upload new one's" />

              <ImageCarousel
                images={[...state.image_urls]}
                eventName={state.name}
              />
            </div>
            <div className="mt-4">
              <RtTitle>Upload Image</RtTitle>

              <RtFileUploader
                setImageUrls={setImageURLs}
                defaultFileUrls={imageURLs?.length > 0 ? imageURLs : []}
                handleFiles={(files) => handleInputChange({ images: files })}
                shouldResize
                maxFile={5}
              />
            </div>
          </SectionContainer>

          <SectionContainer overrideClass="text-center">
            <RtButton onClick={handleUpdateEvent} overrideClass="w-full">
              {updateEventQueryClient.isPending
                ? "UPDATING EVENT..."
                : "UPDATE EVENT"}
            </RtButton>
          </SectionContainer>
        </div>
      </div>

      <ActionLoadingModal
        isOpen={updateEventQueryClient.isPending}
        loadingText="Please wait as your event is being created"
      />
    </div>
  )
}

export default UpdateEvent
