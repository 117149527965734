import { useQuery } from "@tanstack/react-query"
import { ITicketCodeSlug } from "types/index.types"
import { handleGetRequest } from "utils/api-handler.util"

export default function useFetchTicketQrCode({
  event_slug,
  ticket_code,
}: ITicketCodeSlug) {
  //Prevent api call if ticket doesn't have either slug or the code
  if (!event_slug || !ticket_code)
    return { isLoading: false, error: null, data: null }

  const { isLoading, isError, data } = useQuery({
    queryKey: [event_slug, ticket_code],
    queryFn: async () =>
      await handleGetRequest(`/events/${event_slug}/tickets/${ticket_code}`),
  })

  return { isLoading, isError, data }
}
