import "../../assets/styles/spinner.scss"

function Spinner() {
  return (
    <div className="h-full w-full flex justify-center items-center ">
      <div className="donut-ring"></div>
    </div>
  )
}

export default Spinner
