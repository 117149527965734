import RtButton from "components/button/RtButton"
import ActionLoadingModal from "./ActionLoadingModal"
import SectionContainer from "components/layout/SectionContainer"
import RTDatePicker from "components/datepicker/RTDatepicker"
import RtLabel from "components/input/RtLabel"
import RtInput from "components/input/RtInput"
import BaseModal from "./BaseModal"
import RtTitle from "components/title/RtTitle"
import { Plus, X } from "react-feather"
import { useEffect, useState } from "react"
import { TicketGroupInterface } from "types/index.types"
import { useUpdateTicketGroup } from "hooks/api/useUpdateTicketGroup"

interface UpdateTicketGroupModalProps {
  isOpen: boolean
  toggleModal: () => void
  ticketGroup: TicketGroupInterface
  slug: string
}

interface UpdateFormData {
  name: string
  price: number
  start_date: Date | null
  end_date: Date | null
  seats: number
}

function UpdateTicketGroupModal({
  isOpen,
  toggleModal,
  ticketGroup,
  slug,
}: UpdateTicketGroupModalProps) {
  const [formData, setFormData] = useState<UpdateFormData>({
    name: "",
    price: 0,
    start_date: null,
    end_date: null,
    seats: 0,
  })

  const handleInputChange = (value: { [key: string]: any }) => {
    setFormData((prev) => ({ ...prev, ...value }))
  }

  const ticketGroupQueryClient = useUpdateTicketGroup(slug, ticketGroup?.id!)

  const handleUpdateTicketGroup = async () => {
    ticketGroupQueryClient.mutate({ ...formData })
    toggleModal && toggleModal()
  }

  useEffect(() => {
    if (ticketGroup) {
      setFormData({
        name: ticketGroup?.name,
        price: ticketGroup?.price,
        start_date: new Date(ticketGroup?.start_date),
        end_date: new Date(ticketGroup?.end_date),
        seats: ticketGroup?.available_qty,
      })
    }
  }, [ticketGroup])

  return (
    <>
      <BaseModal isOpen={isOpen} onClose={toggleModal}>
        <RtTitle overrideClass="flex justify-between mx-5">
          <span>Update Ticket Group</span>
          <button onClick={toggleModal}>
            <X />
          </button>
        </RtTitle>

        <SectionContainer>
          <div className="flex flex-col gap-5">
            <RtInput
              label="Ticket Name"
              labelOverrideClass="mb-0"
              inputName="name"
              inputValue={formData?.name}
              inputPlaceholder="Regular Ticket"
              onInputChange={handleInputChange}
              isRequired
              inputOverrideClass="w-full"
            />

            <div className="flex gap-3">
              <RtInput
                label="Price"
                labelOverrideClass="mb-0"
                inputName="price"
                inputPlaceholder="500"
                inputValue={formData?.price}
                onInputChange={handleInputChange}
                isRequired
              />

              <RtInput
                label="Seats"
                labelOverrideClass="mb-0"
                inputName="seats"
                inputPlaceholder="500"
                inputValue={formData?.seats}
                onInputChange={handleInputChange}
                isRequired
              />
            </div>

            <div>
              <RtLabel label="Start date & Time" isRequired />

              <RTDatePicker
                placeholderText="Select start date & Time"
                showTimeSelect
                selectedDate={formData?.start_date}
                handleChange={(date: Date | null) =>
                  handleInputChange({ start_date: date })
                }
              />
            </div>

            <div>
              <RtLabel label="End date & Time" isRequired />

              <RTDatePicker
                placeholderText="Select end date & Time"
                showTimeSelect
                selectedDate={formData?.end_date}
                handleChange={(date: Date | null) => {
                  handleInputChange({ end_date: date })
                }}
              />
            </div>
          </div>
        </SectionContainer>
        <SectionContainer overrideClass="flex justify-center">
          <RtButton
            overrideClass="w-full"
            onClick={handleUpdateTicketGroup}
            disabled={ticketGroupQueryClient.isPending}
            icon={
              <Plus className=" text-rt-white mr-2" size={20} strokeWidth={4} />
            }
          >
            {ticketGroupQueryClient.isPending
              ? "UPDATING..."
              : "UPDATE TICKET GROUP"}
          </RtButton>
        </SectionContainer>
      </BaseModal>

      <ActionLoadingModal
        isOpen={ticketGroupQueryClient.isPending}
        loadingText="Adding ticket group..."
      />
    </>
  )
}

export default UpdateTicketGroupModal
