import { twMerge } from "tailwind-merge"
import { RtActionButtonProps } from "types/component-props.types"

function RtActionButton({
  name,
  icon,
  handleClick,
  overrideClass,
}: RtActionButtonProps) {
  return (
    <button
      onClick={handleClick}
      className={twMerge(
        `flex gap-x-1 items-center border-b border-rt-violet text-rt-violet h-min  ${overrideClass}`,
      )}
    >
      {icon || null}
      {name}
    </button>
  )
}

export default RtActionButton
