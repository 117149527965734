interface cartBySlugInterface {
  [ticket_did: string]: {
    count: number
  }
}

export function geSecureTicketFromCart(cartBySlug: cartBySlugInterface) {
  let tickets: any = []

  if (!cartBySlug) return tickets

  for (let [key, value] of Object.entries(cartBySlug)) {
    const isNotTicketId = isNaN(Number(key))

    if (isNotTicketId) continue

    const ticket_ = { ticket_group_id: key, qty: value.count }

    tickets.push(ticket_)
  }

  return tickets
}
