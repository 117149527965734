import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronLeft, ChevronRight, X } from "react-feather"
import eventImage from "../../assets/images/event.jpg"

interface CarouselProps {
  images: string[]
  eventName?: string
}

export default function ImageCarousel({
  images = [],
  eventName,
}: CarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isFullScreen, setIsFullScreen] = useState(false)

  if (!images || images.length === 0) {
    return (
      <div className="w-full max-w-4xl mx-auto aspect-video bg-gray-100 flex items-center justify-center rounded-lg">
        <p className="text-gray-500">{eventName}</p>
      </div>
    )
  }

  const nextSlide = () => {
    if (images.length === 0) return
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const prevSlide = () => {
    if (images.length === 0) return
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    )
  }
  const toggleFullScreen = () => {
    if (images.length > 1) {
      setIsFullScreen(!isFullScreen)
    }
  }

  return (
    <div className="relative w-full max-w-4xl mx-auto flex justify-center flex-1 flex-col items-center">
      <div className="overflow-hidden relative rounded-t-md w-full">
        <motion.div
          className="flex"
          animate={{ x: `-${currentIndex * 100}%` }}
          transition={{ type: "ease", stiffness: 300, damping: 30 }}
        >
          {typeof images === "string" ? (
            <div className="w-full flex-shrink-0">
              <img
                src={images}
                alt={eventName || eventImage}
                className={`w-full h-52 object-contain object-center rounded-t-md`}
              />
            </div>
          ) : (
            images.map((image, index) => (
              <div key={index} className="w-full flex-shrink-0">
                <img
                  src={image}
                  alt={eventName || eventImage || `Slide ${index + 1}`}
                  className={`w-full h-52 object-contain object-center rounded-t-md ${
                    images.length > 1 ? "cursor-pointer" : ""
                  }`}
                  onClick={toggleFullScreen}
                />
              </div>
            ))
          )}
        </motion.div>
        {/* {images.length > 1 && (
          <>
            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              aria-label="Previous slide"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              aria-label="Next slide"
            >
              <ChevronRight size={24} />
            </button>
          </>
        )} */}
      </div>

      {images.length > 0 && typeof images !== "string" && (
        <div className="flex space-x-1 mt-1 mb-1">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                index === currentIndex ? "bg-purple-500" : "bg-purple-300"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      )}

      <AnimatePresence>
        {isFullScreen && images.length > 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50"
            onClick={toggleFullScreen}
          >
            <div
              className="relative max-w-4xl max-h-[90vh] w-full h-full"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={images[currentIndex]}
                alt={
                  eventName ||
                  eventImage ||
                  `Full screen slide ${currentIndex + 1}`
                }
                className="w-full h-full object-contain"
              />
              <button
                className="absolute top-9 right-4 p-2 bg-black bg-opacity-50 text-white rounded-full"
                onClick={toggleFullScreen}
                aria-label="Close full screen"
              >
                <X size={20} />
              </button>
              <button
                className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full"
                onClick={prevSlide}
                aria-label="Previous image"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full"
                onClick={nextSlide}
                aria-label="Next image"
              >
                <ChevronRight size={24} />
              </button>
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 px-3 py-1 rounded-full text-white">
                {currentIndex + 1} / {images.length}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
