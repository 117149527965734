import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import useResendEmailVerificationLink from "hooks/api/useResendEmailVerificatonLink"

function EmailVerificationPrompt({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose?: () => void
}) {
  const verifyEmailQueryClient = useResendEmailVerificationLink()
  const onVerify = () => {
    verifyEmailQueryClient.mutate(undefined, { onSuccess: onClose })
  }
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <p className="text-center text-rt-gray">
        Your email needs verification to proceed.Please click on verify to
        receive a verification email.
      </p>

      <div className="mt-8 flex justify-between">
        <RtButton
          onClick={onClose}
          variant="outline"
          overrideClass="border border-rt-red"
          textOverrideClass="text-rt-red"
        >
          Cancel
        </RtButton>
        <RtButton variant="solid" onClick={onVerify}>
          Send Verification Email
        </RtButton>
      </div>
    </BaseModal>
  )
}

export default EmailVerificationPrompt
