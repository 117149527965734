import RtActionButton from "components/button/RtActionButton"
import BaseModal from "./BaseModal"
import { useNavigate } from "react-router-dom"
import RtButton from "components/button/RtButton"
import { KYC_NONE, KYC_REJECTED } from "constants/global.constants"
import { useSelector } from "react-redux"

function KycAlertModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const has_kyc = useSelector((state: any) => state?.user?.has_kyc)

  const navigate = useNavigate()

  const proceedToKycSetup = () => {
    onClose()
    navigate("/add-kyc")
  }

  const navigateHome = () => {
    onClose()
    navigate("/")
  }

  const explainerText = {
    [KYC_NONE]:
      "We have noticed that your KYC setup is incomplete. Please complete your KYC setup in order to continue hosting events.",
    [KYC_REJECTED]:
      "Your KYC has been rejected. Please update your KYC details.",
  }

  return (
    <BaseModal isOpen={isOpen}>
      <p className="text-center">
        {explainerText[has_kyc as keyof typeof explainerText]}
      </p>

      <div className="flex items-center justify-between gap-2 border-t-2 border-rt-light-gray pt-2 mt-4">
        <RtActionButton name="Home" handleClick={navigateHome} />
        <RtButton onClick={proceedToKycSetup}>Proceed to kyc setup</RtButton>
      </div>
    </BaseModal>
  )
}

export default KycAlertModal
