import SectionContainer from "components/layout/SectionContainer"
import RtTitle from "components/title/RtTitle"
import { useListCategories } from "hooks/api/useListCategories"
import Spinner from "components/loader/Spinner"
import { ICategory } from "types/index.types"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import RtFilterButton from "components/button/RtFilterButton"
import { motion } from "framer-motion"

function BrowseCategory() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const categoryId = searchParams.get("category_id")!

  const { data, isLoading } = useListCategories()

  if (isLoading) return <Spinner />

  return (
    <SectionContainer>
      <RtTitle overrideClass="mb-3">Browse Category</RtTitle>
      <div className="flex flex-wrap gap-2">
        <RtFilterButton
          key={"all"}
          handleClick={() => handleSetUrlParams({ category_id: "" })}
          isSelected={!categoryId}
        >
          All
        </RtFilterButton>
        {data?.data.map((categoryFilter: ICategory) => (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <RtFilterButton
              key={categoryFilter.id}
              handleClick={() =>
                handleSetUrlParams({ category_id: String(categoryFilter.id) })
              }
              isSelected={parseInt(categoryId) === categoryFilter.id}
            >
              {categoryFilter.name}
            </RtFilterButton>
          </motion.div>
        ))}
      </div>
    </SectionContainer>
  )
}

export default BrowseCategory
