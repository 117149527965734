import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { handleRemoveAccessToken } from "redux/features/global"
import { handLogOutUser } from "redux/features/user"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useLogout(toggleModal: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  const mutation = useMutation({
    mutationFn: async () => {
      await handlePostRequest("/logout", {})
    },
    onSuccess: () => {
      showToaster("success", "You have successfully been logged out")
      dispatch(handleRemoveAccessToken())
      dispatch(handLogOutUser())
      dispatch(clearAllValidationErrors())

      toggleModal()

      const isNotHome = currentPath !== "/"
      if (isNotHome) {
        navigate("/")
      }
    },
    onError: (err: any) => {
      toggleModal()
      handleError(err)
    },
  })

  return mutation
}
