import { RtLabelProps } from "types/component-props.types"

function RtLabel({ label, isRequired, overrideClass }: RtLabelProps) {
  return (
    <label className={`block text-left mb-1 text-rt-gray ${overrideClass}`}>
      {label}

      {isRequired && <span className="text-rt-red">*</span>}
    </label>
  )
}

export default RtLabel
