import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { useDispatch } from "react-redux"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"
import { handleError } from "utils/api-handler.util"

function useSellTickets(slug: string, handleNavigate?: () => void) {
  const dispatch = useDispatch()
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationKey: ["sellTickets"],
    mutationFn: async (data: Record<string, any>) => {
      const response = await handlePostRequest(
        `admin/events/${slug}/sell-tickets`,
        data,
      )
      return response
    },
    onSuccess: () => {
      showToaster("success", "You have successfully sold tickets", 5000)
      dispatch(clearAllValidationErrors())
      handleNavigate && handleNavigate()
    },
    onError: (err: any) => {
      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
      handleError(err)
    },
  })

  return mutation
}

export default useSellTickets
