import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  accessToken: null,
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    handleLoading: (state, action) => {
      state.loading = action.payload
    },
    handleAddAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    handleRemoveAccessToken: (state) => {
      state.accessToken = null
    },
  },
})

export const { handleLoading, handleAddAccessToken, handleRemoveAccessToken } =
  globalSlice.actions

export const globalReducer = globalSlice.reducer
