import WarningIllustration from "../../assets/illustrations/warning-illustration.svg"

function ErrorFallback() {
  return (
    <div className="flex flex-col items-center justify-center gap-8 w-full">
      <WarningIllustration />
      <h5 className="text-rt-red font-bold font-poppins">
        Oops! Something went wrong while fetching the data. Please try again
        later.
      </h5>
    </div>
  )
}

export default ErrorFallback
