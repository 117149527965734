import SectionContainer from "components/layout/SectionContainer"
import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { RequestWithdrawalModalProps } from "types/component-props.types"
import ActionLoadingModal from "./ActionLoadingModal"
import { useRequestWithdrawal } from "hooks/api/useRequestWithdrawal"
import RtTitle from "components/title/RtTitle"

function RequestWithdrawalModal({
  isOpen,
  onClose,
  slug,
}: RequestWithdrawalModalProps) {
  const requestWithdrawalQueryClient = useRequestWithdrawal(slug)

  const onRequestWithdrawal = () => {
    onClose()
    requestWithdrawalQueryClient.mutate()
  }

  return (
    <>
      <ActionLoadingModal
        loadingText="Please wait as the event is being cancelled..."
        isOpen={requestWithdrawalQueryClient.isPending}
      />
      <BaseModal isOpen={isOpen} onClose={onClose}>
        <SectionContainer overrideClass="font-poppins">
          <RtTitle>Withdrawal request</RtTitle>
          <p className="my-2 text-center">
            By Clicking continue a withdrawal request will be sent for approval.
          </p>

          <div className="mt-8 flex justify-between">
            <RtButton
              onClick={onClose}
              variant="outline"
              overrideClass="border border-rt-red"
              textOverrideClass="text-rt-red"
            >
              Cancel
            </RtButton>
            <RtButton variant="solid" onClick={onRequestWithdrawal}>
              Continue
            </RtButton>
          </div>
        </SectionContainer>
      </BaseModal>
    </>
  )
}

export default RequestWithdrawalModal
