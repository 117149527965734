import { DollarSign, Layers } from "react-feather"
import SummaryStat from "./summary-stat"
import { SalesSummaryProps } from "types/component-props.types"

function RevenueStats({ revenueStats }: SalesSummaryProps) {
  return (
    <div className="bg-rt-blue p-4 rounded-md flex justify-between">
      <SummaryStat
        value={revenueStats?.revenue || 0}
        title="Revenue"
        icon={
          <DollarSign className="text-rt-violet" size={20} strokeWidth={2} />
        }
      />
      <SummaryStat
        value={revenueStats?.sales || 0}
        title="Sales"
        icon={<Layers className="text-rt-violet" size={20} strokeWidth={2} />}
      />
    </div>
  )
}

export default RevenueStats
