import RtButton from "components/button/RtButton"
import RTPassword from "components/input/RTPassword"
import SectionContainer from "components/layout/SectionContainer"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import PageNav from "components/nav/PageNav"
import AuthLayout from "components/responsive-layouts/AuthLayout"
import { useResetPassword } from "hooks/api/useResestPassword"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

function ResetPassword() {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const token = searchParams.get("token") || ""
  const [formValues, setFormValues] = useState({
    token,
    password: "",
    password_confirmation: "",
  })

  const handleInputChange = (value: { [key: string]: any }) => {
    setFormValues((prev) => ({ ...prev, ...value }))
  }

  const queryClient = useResetPassword(() => navigate("/login"))
  const handleSubmitFormData = async () => {
    queryClient.mutate(formValues)
  }

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmitFormData()
    }
  }

  return (
    <div className="px-2">
      <SectionContainer>
        <PageNav overrideWrapper="p-0" backText="Home">
          Reset Password
        </PageNav>
        <p className="text-center text-rt-gray mt-2">
          Please enter your password.
        </p>

        <AuthLayout>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmitFormData()
            }}
          >
            <div className="mt-8 flex flex-col gap-2">
              <RTPassword
                label="Password"
                handleInputChange={handleInputChange}
              />

              <RTPassword
                label="Confirm password"
                inputName="password_confirmation"
                inputPlaceholder="Confirm password"
                handleEnterKey={handleEnterKey}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="mt-8">
              <RtButton
                variant="solid"
                overrideClass="w-full "
                onClick={() => handleSubmitFormData()}
                disabled={queryClient.isPending}
              >
                Reset Password
              </RtButton>

              <RtButton
                variant="link"
                overrideClass="w-full mt-4 text-rt-blue"
                textOverrideClass="text-[14px]"
                disabled={queryClient.isPending}
                onClick={() => navigate("/login")}
              >
                Back to Login
              </RtButton>
            </div>
          </form>
        </AuthLayout>
      </SectionContainer>
      <ActionLoadingModal
        isOpen={queryClient.isPending}
        loadingText="Please wait as we reset your password"
      />
    </div>
  )
}

export default ResetPassword
