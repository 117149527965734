import { Config } from "types/context.types"
const env = import.meta.env

export const APP_ENVIRONMENT = env.VITE_ENV

export const config: Config = {
  BASE_API_URL: `${env.VITE_API_URL}`,
  SENTRY_DSN: env.VITE_SENTRY_DSN,
}

export const isProduction = APP_ENVIRONMENT === "production"

export const HIDE_NAV_ITEMS_PATHS = ["checkout", "login", "register"]

export const STATUS_CODE_OK_MIN = 200

export const STATUS_CODE_OK_MAX = 299

export const CURRENT_TAB = "current"

export const CATEGORIES = [
  {
    id: 1,
    name: "Conferences",
  },
  {
    id: 2,
    name: "Concerts",
  },
  {
    id: 3,
    name: "Sports",
  },
  {
    id: 4,
    name: "Arts & Theater",
  },
  {
    id: 5,
    name: "Family",
  },
]

export const NETWORK_ERROR_CODE = "ERR_NETWORK"

export const ADMIN = "admin"

export const HOST = "host"

export const REGULAR = "regular"

// TICKET STATUS
export const COMING_SOON = 1
export const CLOSED = 2
export const SOLD_OUT = 3
export const SALES_CLOSED = 4 // all available tickets are locked
export const ON_GOING = 5

//unAuth route
export const UN_AUTHENTICATED_ROUTES = ["login", "sign-up"] as const

//EVENT STATUS
export const EVENT_DRAFT = 1
export const EVENT_IN_REVIEW = 2
export const EVENT_PUBLISHED = 3
export const EVENT_REJECTED = 4
export const EVENT_CANCELLED = 5
export const EVENT_COMPLETED = 6

export const SUPPORT_EMAIL = "support@rapidtickethub.com"


//KYC STATES  
export const KYC_NONE = 0
export const KYC_PENDING_APPROVAL = 1
export const KYC_APPROVED = 2
export const KYC_REJECTED = 3



//KYC REVIEW STATES
export const ADMIN_KYC_APPROVE = 1

export const ADMIN_KYC_REJECT = 2


//TOASTER IDs
export const TOASTER_ID = {
  kyc: "KYC",
  scanTicketError: "SCAN_TICKET_ERROR",
} as const


//EVENT TYPES
export const ACTIVE_EVENTS = 1
export const PAST_EVENTS = 0

//EVENT LIST STYLES
export const LIST_STYLE_GRID = "grid"
export const LIST_STYLE_CAROUSEL = "carousel"

//PERIODS
export const PERIODS = {
  PAST: {
    label: "Past",
    value: "past",
  },
  UPCOMING: {
    label: "Upcoming",
    value: "upcoming",
  },
  THIS_WEEK: {
    label: "This week",
    value: "this_week",
  },
  NEXT_WEEK: {
    label: "Next week",
    value: "next_week",
  },
  THIS_MONTH: {
    label: "This month",
    value: "this_month",
  },
  NEXT_MONTH: {
    label: "Next month",
    value: "next_month",
  },
  CUSTOM: {
    label: "Custom",
    value: "custom",
  },
} as const

type TPeriods = typeof PERIODS
export type TPeriodKey = keyof TPeriods
export type TPeriod = TPeriods[TPeriodKey]
export type TPeriodLabel = TPeriod["label"]
export type TPeriodValue = TPeriod["value"]


export const RAPID_TECH_INSIGHTS_DOMAIN = "https://ticketvipi.com"
