function createUserType(user: any) {
  let user_type = "regular"

  if (user?.is_host) user_type = "host"
  if (user?.is_admin) user_type = "admin"

  return user_type
}

export { createUserType }
