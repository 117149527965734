import RtButton from "components/button/RtButton"
import RtInput from "components/input/RtInput"
import SectionContainer from "components/layout/SectionContainer"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import PageNav from "components/nav/PageNav"
import AuthLayout from "components/responsive-layouts/AuthLayout"
import RtTitle from "components/title/RtTitle"
import { useForgotPassword } from "hooks/api/useForgotPassword"
import { useState } from "react"
import { User } from "react-feather"
import { useNavigate } from "react-router-dom"

function ForgotPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")

  const handleInputChange = (value: { [key: string]: any }) => {
    setEmail(value.email)
  }

  const queryClient = useForgotPassword()
  const handleSubmitFormData = async () => {
    queryClient.mutate({ email })
  }

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmitFormData()
    }
  }

  return (
    <div className="px-2">
      <SectionContainer>
        <PageNav overrideWrapper="p-0" backText="Home">
          <RtTitle>Forgot Password</RtTitle>
        </PageNav>
        <p className="text-center text-rt-gray mt-2">
          Please enter your email address to reset your password.
        </p>

        <AuthLayout>
          <div className="mt-8 flex flex-col gap-2">
            <RtInput
              label="Email"
              inputName="email"
              inputType="text"
              inputPlaceholder="Email"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              inputValue={email}
              onKeyUp={handleEnterKey}
              onInputChange={handleInputChange}
              isDisabled={queryClient.isPending}
              isRequired
              icon={
                <User className=" text-rt-blue" size={16} strokeWidth={1} />
              }
            />
          </div>
          <div className="mt-8">
            <RtButton
              variant="solid"
              type="submit"
              overrideClass="w-full mt-4"
              onClick={handleSubmitFormData}
              disabled={queryClient.isPending}
            >
              Proceed to reset password
            </RtButton>
          </div>

          <RtButton
            variant="link"
            overrideClass="w-full mt-4 text-rt-blue"
            textOverrideClass="text-[14px]"
            disabled={queryClient.isPending}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </RtButton>
        </AuthLayout>
      </SectionContainer>

      <ActionLoadingModal
        isOpen={queryClient.isPending}
        loadingText="Please wait as we send your email"
      />
    </div>
  )
}

export default ForgotPassword
