import React from "react"

interface RtTabLayoutProps {
  children: React.ReactNode
  overrideClass?: string
}

function TabLayout({ children, overrideClass }: RtTabLayoutProps) {
  return (
    <div className={`w-full bg-rt-white  flex justify-evenly ${overrideClass}`}>
      {children}
    </div>
  )
}

export default TabLayout
