import SectionContainer from "components/layout/SectionContainer"
import Spinner from "components/loader/Spinner"
import Pagination from "components/pagination/pagination"
import { useListWithdrawalRequests } from "hooks/api/useListWithdrawalRequests"
import AdminEventLink from "pages/admin-dashboard/admin-event-link"
import { useSearchParams } from "react-router-dom"
import { EventInterFace } from "types/index.types"

function WithdrawalsRequests() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get("page") ?? 1

  const { data, isLoading } = useListWithdrawalRequests({ page })

  const handlePageChange = (nextPage: number) => {
    setSearchParams((prevParams) => {
      prevParams.set("page", nextPage.toString())
      return prevParams
    })
  }

  return (
    <div>
      <SectionContainer sectionTitle="Withdrawal requests">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-col gap-2">
              {data?.data.data.length === 0 && (
                <p>There are no withdrawal request at the moment...</p>
              )}

              {data?.data.data.map((event: Partial<EventInterFace>) => (
                <AdminEventLink
                  key={`withdrawal-request-${event.slug}`}
                  event={event}
                />
              ))}
            </div>

            {data?.data.meta.next_page_url && (
              <div className="flex justify-center mt-10">
                <Pagination
                  meta={{ ...data?.data?.meta }}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
          </>
        )}
      </SectionContainer>
    </div>
  )
}

export default WithdrawalsRequests
