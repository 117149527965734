import { RtCarouselProps } from "types/component-props.types"

function RtCarousel({ children, overrideClass }: RtCarouselProps) {
  return (
    <div className={`flex flex-row overflow-x-scroll gap-x-3 ${overrideClass}`}>
      {children}
    </div>
  )
}

export default RtCarousel
