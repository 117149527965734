import RtSearch from "components/input/RtSearch"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import HostEventsList from "./host-event-list"
import { ACTIVE_EVENTS, PAST_EVENTS } from "constants/global.constants"
import SectionContainer from "components/layout/SectionContainer"
import FiltersLayout from "components/responsive-layouts/FiltersLayout"

function MyEvents() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const q = searchParams.get("q")

  return (
    <div>
      <SectionContainer>
        <FiltersLayout>
          <RtSearch
            handleSearch={(value: string) => handleSetUrlParams({ q: value })}
            searchValue={q || ""}
          />
        </FiltersLayout>
      </SectionContainer>

      <HostEventsList isActive={ACTIVE_EVENTS} listStyle="carousel" />
      <HostEventsList isActive={PAST_EVENTS} listStyle="grid" />
    </div>
  )
}

export default MyEvents
