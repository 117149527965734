import { AlertTriangle } from "react-feather"

interface RtBannerProps {
  title?: string
  description: React.ReactNode
  overrideWrapperStyles?: string
  overrideTextStyles?: string
  bannerType?: "info" | "warning" | "success" | "error"
  action?: React.ReactNode
}

function RtBanner({
  title,
  description,
  overrideWrapperStyles,
  overrideTextStyles,
  bannerType = "info",
  action,
}: RtBannerProps) {
  const type = {
    info: {
      wrapper: "bg-blue-50 border-blue-400 border-2",
      title: "text-blue-700",
      description: "text-blue-500",
      iconColor: "text-blue-700",
      actionBorder: "border-blue-700",
    },
    warning: {
      wrapper: "bg-orange-50 border-orange-400 border-2",
      title: "text-orange-700",
      description: "text-orange-600",
      iconColor: "text-orange-700",
      actionBorder: "border-orange-700",
    },
    success: {
      wrapper: "bg-green-50 border-green-400 border-2",
      title: "text-green-700",
      description: "text-green-600",
      iconColor: "text-green-700",
      actionBorder: "border-green-700",
    },
    error: {
      wrapper: "bg-red-50 border-red-400 border-2",
      title: "text-red-700",
      description: "text-red-500",
      iconColor: "text-red-700",
      actionBorder: "border-red-700",
    },
  }

  const wrapperStyles = type[bannerType].wrapper
  const titleStyles = type[bannerType].title
  const descriptionStyle = type[bannerType].description
  const iconColor = type[bannerType].iconColor

  return (
    <div
      className={`p-3 text-sm rounded-lg flex justify-between items-center ${wrapperStyles} ${overrideWrapperStyles}`}
      role="alert"
    >
      <div className="flex gap-2">
        <AlertTriangle size={25} className={`${iconColor} font-bold`} />

        <div className="flex flex-col mr-2">
          {title && (
            <span className={`font-bold capitalize text-sm ${titleStyles}`}>
              {title}
            </span>
          )}
          <div
            className={`font-light text-xs ${descriptionStyle} ${overrideTextStyles}`}
          >
            {description}
          </div>
        </div>
      </div>

      {action && <>{action}</>}
    </div>
  )
}

export default RtBanner
