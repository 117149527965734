import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import RtFilterButton from "components/button/RtFilterButton"
import { motion } from "framer-motion"
import { EVENT_IN_REVIEW, EVENT_PUBLISHED } from "constants/global.constants"

const EVENT_STATUS = [
  { name: "All", id: "" },
  { name: "In Review", id: EVENT_IN_REVIEW },
  { name: "Published", id: EVENT_PUBLISHED },
]

function RtFilterByStatus() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const status = searchParams.get("status")

  const isSelected = (statusId: number | string) => {
    if (status) {
      return parseInt(status) == statusId
    }

    return typeof statusId === "string"
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="flex flex-wrap gap-2">
        {EVENT_STATUS.map((eventStatus) => (
          <RtFilterButton
            key={eventStatus.id}
            handleClick={() =>
              handleSetUrlParams({ status: String(eventStatus.id) })
            }
            isSelected={isSelected(eventStatus.id)}
          >
            {eventStatus.name}
          </RtFilterButton>
        ))}
      </div>
    </motion.div>
  )
}

export default RtFilterByStatus
