import { RowsPerPageSelectorProps } from "types/component-props.types"
import BaseDropdown from "./BaseDropdown"
import RtButton from "components/button/RtButton"
import { ChevronDown } from "react-feather"

function RowsPerPageSelector({
  onChange,
  value,
  options = [20, 50, 100, 200, 500],
  currentPage,
  totalPages,
}: RowsPerPageSelectorProps) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-sm text-gray-600">
        Page {currentPage} of {totalPages}
      </span>
      <BaseDropdown
        placement="above"
        trigger={
          <RtButton
            id="rows-per-page"
            className="px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pr-8"
          >
            {value}
            <span className="absolute inset-y-0 right-0 flex items-center px-2">
              <ChevronDown className="h-4 w-4 text-gray-400" />
            </span>
          </RtButton>
        }
      >
        <div className="py-1">
          {options.map((option) => (
            <RtButton
              key={option}
              onClick={() => onChange(option)}
              className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
            >
              {option}
            </RtButton>
          ))}
        </div>
      </BaseDropdown>
    </div>
  )
}

export default RowsPerPageSelector
