import SectionContainer from "components/layout/SectionContainer"
import MyTicketTabs from "./my-ticket-tabs"
import { useListMyTickets } from "hooks/api/useListMyTicket"
import Spinner from "components/loader/Spinner"
import RtButton from "components/button/RtButton"
import { useState } from "react"
import TicketQrCodeModal from "components/modal/TicketQrCodeModal"
import TicketPreview from "components/ticket/TicketPreview"
import RtGrid from "components/grid/RtGrid"
import { AnimatePresence, motion } from "framer-motion"
import { TicketDetails } from "types/component-props.types"

export const MyTickets = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useListMyTickets()
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useState<TicketDetails>({
      end_date: "",
      event_location: "",
      event_name: "",
      event_slug: "",
      event_status: "",
      price: "",
      start_date: "",
      ticket_code: "",
      ticket_group_name: "",
      sold_at: "",
    })

  const handleOpenTicketQrCodeModal = (ticketDetails: TicketDetails) => {
    setSelectedTicketDetails(ticketDetails)
    setIsModalOpen(true)
  }

  const tickets = data?.pages?.flatMap((page) => page.data.data) || []
  const noTickets = tickets.length === 0

  return (
    <div>
      <MyTicketTabs />
      <SectionContainer overrideClass="flex flex-col gap-y-2">
        {isLoading && <Spinner />}
        <RtGrid>
          <AnimatePresence>
            {!isLoading &&
              tickets.map((ticket, index: number) => (
                <motion.div
                  key={`motion-${index}`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <TicketPreview
                    linkTo={`/events/${ticket.event_slug}`}
                    key={`ticket-${index}`}
                    event={ticket}
                    ticketType={ticket.ticket_group_name || ""}
                  >
                    <div className="text-right mt-2">
                      <RtButton
                        onClick={() =>
                          handleOpenTicketQrCodeModal({
                            end_date: ticket.end_date!,
                            event_location: ticket.event_location!,
                            event_name: ticket.event_name!,
                            event_slug: ticket.event_slug!,
                            event_status: ticket.event_status!,
                            price: String(ticket.price)!,
                            start_date: ticket.start_date!,
                            ticket_code: ticket.ticket_code!,
                            ticket_group_name: ticket.ticket_group_name!,
                            sold_at: ticket.sold_at!,
                          })
                        }
                      >
                        SHOW QR CODE
                      </RtButton>
                    </div>
                  </TicketPreview>
                </motion.div>
              ))}
          </AnimatePresence>
        </RtGrid>

        {!isLoading && noTickets && <p>No tickets available.</p>}

        <SectionContainer overrideClass="flex justify-center">
          {isFetchingNextPage && <Spinner />}
          {!isFetchingNextPage && hasNextPage && (
            <RtButton
              id="more"
              overrideClass="w-[8rem]"
              onClick={() => fetchNextPage()}
            >
              More tickets
            </RtButton>
          )}
        </SectionContainer>
      </SectionContainer>
      <TicketQrCodeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ticketDetails={selectedTicketDetails}
      />
    </div>
  )
}
