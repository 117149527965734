import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"
import { showToaster } from "utils/show-toaster.util"

export const usePublishEvent = (slug: string) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async () => handlePostRequest(`/events/${slug}/publish`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(slug),
      })

      showToaster(
        "success",
        "Your event has been sent in for review,It will be published pending internal approval.",

        5000,
      )
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}
