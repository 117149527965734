import { combineReducers } from "@reduxjs/toolkit"
import { cartReducer } from "./features/cart"
import { globalReducer } from "./features/global"
import { userReducer } from "./features/user"
import { formErrorsReducer } from "./features/formErrors"

const rootReducer = combineReducers({
  cart: cartReducer,
  global: globalReducer,
  user: userReducer,
  formErrors: formErrorsReducer,
})

export default rootReducer
