import Resizer from "react-image-file-resizer"

interface IResizeImage {
  file: File
  maxWidth: number
  maxHeight: number
  callback: (resizedFiles: any) => void
  outPutType?: "blob" | "base64" | "file" | "uri"
}

function resizeImage({
  file,
  maxWidth,
  maxHeight,
  callback,
  outPutType = "file",
}: IResizeImage) {
  Resizer.imageFileResizer(
    file,
    maxWidth,
    maxHeight,
    "jpeg",
    100,
    0,
    (resizedFile) => {
      callback(resizedFile)
    },
    outPutType,
    0,
    0,
  )
}

export default resizeImage
