import RtButton from "components/button/RtButton"
import RTDatePicker from "components/datepicker/RTDatepicker"
import RtLabel from "components/input/RtLabel"
import BaseModal from "components/modal/BaseModal"
import RtTitle from "components/title/RtTitle"
import { useState } from "react"
import { EventActionModalProps } from "types/component-props.types"
import { __getUnixTime } from "utils/date-formatter.util"

export interface CustomDatePickerModalProps
  extends Omit<EventActionModalProps, "slug"> {
  handleApply: (value: string) => void
}

function CustomDateModal({
  isOpen,
  onClose,
  handleApply,
}: CustomDatePickerModalProps) {
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
  })

  const handleChange = (value: Record<string, Date | null>) => {
    setFormData((prev) => ({ ...prev, ...value }))
  }

  const onApply = () => {
    const { start_date, end_date } = formData

    if (!start_date || !end_date) {
      return
    }

    let startDateUnix = __getUnixTime(start_date)
    let endDateUnix = __getUnixTime(end_date)

    handleApply(`custom:${startDateUnix},${endDateUnix}`)
    onClose()
  }

  if (!isOpen) return null

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <RtTitle>Filter by custom date</RtTitle>
      <div className="flex flex-col justify-start gap-4 mt-4">
        <div>
          <RtLabel label="Start Date" isRequired />

          <RTDatePicker
            selectedDate={formData?.start_date}
            handleChange={(date: Date | null) =>
              handleChange({ start_date: date })
            }
            placeholderText="Select start date"
          />
        </div>
        <div>
          <RtLabel label="End Date" isRequired />

          <RTDatePicker
            selectedDate={formData?.end_date}
            handleChange={(date: Date | null) =>
              handleChange({ end_date: date })
            }
            placeholderText="Select end date "
          />
        </div>
      </div>

      <div className="border border-rt-gray opacity-10 my-4" />

      <div className="flex justify-between">
        <RtButton
          onClick={onClose}
          variant="outline"
          overrideClass="border border-rt-red"
          textOverrideClass="text-rt-red"
        >
          Cancel
        </RtButton>
        <RtButton variant="solid" onClick={onApply}>
          Apply
        </RtButton>
      </div>
    </BaseModal>
  )
}

export default CustomDateModal
