import SectionContainer from "components/layout/SectionContainer";
import { createGenderOption } from "components/search-select/GenderSearchSelect";
import useAddKyc from "hooks/api/useAddKyc";
import { getLoggedInUser } from "hooks/api/useGetLoggedInUser";
import AddKycDetails from "pages/host-event/add-kyc-details";
import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handLogInUser } from "redux/features/user";
import { KcyFormDataType } from "types/form-data.types";
import { IGender, ILocation } from "types/index.types";
import { createUserType } from "utils/create-user-type";
import { showToaster } from "utils/show-toaster.util";

function AddKYC() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Memoize default values to avoid unnecessary reinitialization
  const defaultValues = useMemo(() => location.state?.defaultValues || {}, [
    location.state,
  ]);

  const [kycData, setKycData] = useState<KcyFormDataType>({
    firstname: defaultValues?.firstname || "",
    lastname: defaultValues?.lastname || "",
    phone_number: defaultValues?.phone_number || "",
    id_number: defaultValues?.id_number || "",
    company_name: defaultValues?.company_name || "",
    gender: defaultValues?.gender
      ? (createGenderOption(defaultValues?.gender) as IGender)
      : ({ value: "", label: "" } as IGender),
    nok_name: defaultValues?.nok_name || "",
    nok_phone_number: defaultValues?.nok_phone_number || "",
    id_photo: defaultValues?.id_photo || "",
    passport: defaultValues?.passport || "",
    location_place_id: defaultValues?.location_name
      ? {
          description: defaultValues?.location_name,
          place_id: "",
        }
      : ({ place_id: "", description: "" } as ILocation),
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Prevent duplicate submissions

  const handleKycInputChange = (value: { [key: string]: any }) => {
    setKycData((prev) => ({ ...prev, ...value }));
  };

  const onKycSuccess = async () => {
    try {
      const response = await getLoggedInUser();
      const user_type = createUserType(response?.data);
      dispatch(handLogInUser({ ...response?.data, user_type }));

      // Navigate to the host-event page
      navigate("/host-event", { state: { tab: "Create event" } });
    } catch (error) {
      showToaster("error", "An error occurred while creating your KYC details");
      // Redirect to home if there's an issue
      navigate("/");
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };

  const kycQueryClient = useAddKyc();

  const handleCreateKycDetails = async () => {
    if (isSubmitting) return; // Prevent duplicate submissions
    setIsSubmitting(true);

    const formData = formatKycFormData(kycData);

    try {
      await kycQueryClient.mutateAsync(formData);
      await onKycSuccess();
    } catch (error) {
      console.error("Error submitting KYC details:", error);
      showToaster("error", "Failed to submit KYC details. Please try again.");
    } finally {
      setIsSubmitting(false); // Ensure state resets even on error
    }
  };

  return (
    <SectionContainer>
      <AddKycDetails
        isEdit={!!Object.keys(defaultValues).length}
        formData={kycData}
        handleChange={handleKycInputChange}
        isAddingKyc={kycQueryClient.isPending || isSubmitting}
        handleCreateKycDetails={handleCreateKycDetails}
      />
    </SectionContainer>
  );
}

export default AddKYC;

function formatKycFormData(kycForm: KcyFormDataType) {
  const formData = new FormData();

  formData.append("firstname", kycForm.firstname);
  formData.append("lastname", kycForm.lastname);
  formData.append("company_name", kycForm.company_name);
  formData.append("phone_number", String(kycForm.phone_number));
  formData.append("id_number", String(kycForm.id_number));
  formData.append("gender", kycForm.gender.value);
  formData.append("nok_name", kycForm.nok_name);
  formData.append("nok_phone_number", String(kycForm.nok_phone_number));
  formData.append("id_photo", kycForm.id_photo);
  formData.append("passport", kycForm.passport);
  formData.append(
    "location_place_id",
    String(kycForm.location_place_id.place_id)
  );

  return formData;
}
