import SectionContainer from "components/layout/SectionContainer"
import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { useState } from "react"
import RtTextArea from "components/input/RtTextArea"
import { RejectEventModalProps } from "types/component-props.types"
import { useRejectEvent } from "hooks/api/useRejectEvent"
import ActionLoadingModal from "./ActionLoadingModal"

function RejectEventModal({ isOpen, onClose, slug }: RejectEventModalProps) {
  const [formData, setFormData] = useState({ reason: "" })

  const queryClient = useRejectEvent()

  const onReject = () => {
    onClose()
    queryClient.mutate({ slug, formData })
  }

  const onInputChange = (value: any) => {
    setFormData(value)
  }

  return (
    <>
      <ActionLoadingModal
        loadingText="Your is being processed..."
        isOpen={queryClient.isPending}
      />
      <BaseModal isOpen={isOpen} onClose={onClose}>
        <SectionContainer overrideClass="font-poppins">
          <p className="mb-2">Are you sure you want to reject this event?</p>

          <RtTextArea
            inputName="reason"
            label="Reason"
            isRequired
            labelOverrideClass="text-rt-gray"
            inputPlaceholder="Describe why you are rejecting this event"
            onInputChange={onInputChange}
          />

          <div className="mt-8 flex justify-between">
            <RtButton
              onClick={onClose}
              variant="outline"
              overrideClass="border border-rt-red"
              textOverrideClass="text-rt-red"
            >
              Cancel
            </RtButton>
            <RtButton variant="solid" onClick={onReject}>
              Reject
            </RtButton>
          </div>
        </SectionContainer>
      </BaseModal>
    </>
  )
}

export default RejectEventModal
