import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { handleAddAccessToken } from "redux/features/global"
import { handLogInUser } from "redux/features/user"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { createUserType } from "utils/create-user-type"
import { showToaster } from "utils/show-toaster.util"

export function useSignUp(redirectUrl: string = "/") {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationFn: async (formData: any) => {
      const response = await handlePostRequest("/signup", formData)

      return response
    },
    onSuccess: (response) => {
      const { token, user } = response?.data.data

      const user_type = createUserType(user)

      dispatch(handleAddAccessToken(token))
      dispatch(handLogInUser({ ...user, user_type }))
      dispatch(clearAllValidationErrors())

      navigate(redirectUrl)
      showToaster("success", "Your account has been successfully created.")
    },
    onError: (err: any) => {
      handleError(err)

      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
    },
  })

  return mutation
}