import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"
import MyEventDetails from "pages/my-event-details"
import HostEvent from "pages/host-event"
import EventDetails from "pages/event-details"
import Checkout from "pages/checkout"
import SignUp from "pages/sign-up/SignUp"
import Login from "pages/login/Login"
import { MyTickets } from "pages/my-tickets"
import AdminDashboard from "pages/admin-dashboard"
import Home from "pages/home"
import UserLayout from "components/layout/UserLayout"
import {
  canHostEvent,
  canViewMyEvents,
  canViewMyTickets,
  isAdmin,
  isAuthenticated,
} from "utils/user.util"
import MyEvents from "pages/my-events"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import Sales from "pages/sales"
import { ProtectedRoute } from "routes/ProtectedRoute"
import AdminEvents from "pages/admin-events"
import ConfirmPayment from "pages/confirm-payment"
import UpdateEvent from "pages/update-event"
import Profile from "pages/profile"
import AddKYC from "pages/add-kyc"
import SellTickets from "pages/sell-tickets"
import EmailVerified from "pages/email-verified"
import HostProfile from "pages/host-profile"
import HostKYC from "pages/host-kyc"
import WithdrawalsRequests from "pages/withdrawal-requests"
import PrivacyPolicy from "pages/privacy-policy"
import TermsOfService from "pages/terms-of-service"
import PageNotFound from "components/fallback/PageNotFound"
import ForgotPassword from "pages/reset-password/forgot-password"
import ResetPassword from "pages/reset-password/reset-password"
import FAQPage from "pages/faq"

export default function Routes() {
  const location = window.location.pathname
  const search = window.location.search
  const user = useSelector((state: any) => state.user)

  const routes_ = useMemo(() => {
    const routes: RouteObject[] = [
      {
        path: "/",
        element: <UserLayout />,
        children: [
          {
            path: "/sign-up",
            element: <SignUp />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/password-reset",
            element: <ResetPassword />,
          },
          // https://app.rapidtickethub.com/password-reset?token=cfmxkQPj5MiHxa2eE8nydUBTkpYmFHOS2_ZSW-lYm-EAcV7dw1KRiKHulXXrpgxdnLPc6SKHo2Y5mzfCN1_E08pb3JRgyFBSEmxTZkB6jWSbz4iv3tPTz1lyQrL0Z906.bkYzY2RPSXY5b0p4Q21mNw.6s_dTvQ961t4tRm7iLg-milnrfswkrQJ6O9Qhbxv3Ro
          {
            path: "/forgot-password",
            element: <ForgotPassword />,
          },
          {
            index: true,
            element: isAdmin() ? (
              <ProtectedRoute can={isAdmin()}>
                <AdminDashboard />
              </ProtectedRoute>
            ) : (
              <Home />
            ),
          },
          {
            path: "/my-tickets",
            element: (
              <ProtectedRoute can={canViewMyTickets()}>
                <MyTickets />
              </ProtectedRoute>
            ),
          },
          {
            path: "/my-events",
            element: (
              <ProtectedRoute can={canViewMyEvents()}>
                <MyEvents />
              </ProtectedRoute>
            ),
          },
          {
            path: "/events/:slug",
            element: <EventDetails />,
          },
          {
            path: "/checkout/:slug/:expiresAt/:lockId",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <Checkout />
              </ProtectedRoute>
            ),
          },
          {
            path: "/confirm-payment",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <ConfirmPayment />
              </ProtectedRoute>
            ),
          },
          {
            path: "/host-event",
            element: (
              <ProtectedRoute can={canHostEvent()}>
                <HostEvent />
              </ProtectedRoute>
            ),
          },
          {
            path: "/update-event/:slug",
            element: (
              <ProtectedRoute can={canHostEvent()}>
                <UpdateEvent />
              </ProtectedRoute>
            ),
          },

          {
            path: "/my-events/:slug",
            element: (
              <ProtectedRoute can={canHostEvent()}>
                <MyEventDetails />
              </ProtectedRoute>
            ),
          },
          {
            path: "/sales",
            element: (
              <ProtectedRoute can={canHostEvent()}>
                <Sales />
              </ProtectedRoute>
            ),
          },
          {
            path: "/events",
            element: (
              <ProtectedRoute can={isAdmin()}>
                <AdminEvents />
              </ProtectedRoute>
            ),
          },
          {
            path: "/profile/:userId",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <Profile />
              </ProtectedRoute>
            ),
          },
          {
            path: "/add-kyc",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <AddKYC />
              </ProtectedRoute>
            ),
          },
          {
            path: "/sell-tickets/:slug/:lockId",
            element: (
              <ProtectedRoute can={isAdmin()}>
                <SellTickets />
              </ProtectedRoute>
            ),
          },
          {
            path: "/email-verified",
            element: <EmailVerified />,
          },
          {
            path: "/host/:hostId",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <HostProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: "/my-kyc",
            element: (
              <ProtectedRoute can={isAuthenticated()}>
                <HostKYC />
              </ProtectedRoute>
            ),
          },
          {
            path: "/withdrawal-requests",
            element: (
              <ProtectedRoute can={isAdmin()}>
                <WithdrawalsRequests />
              </ProtectedRoute>
            ),
          },
          {
            path: "/faq",
            element: <FAQPage />,
          },
        ],
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "test-sentry",
        element: (
          <div
            onClick={() => {
              throw new Error("Test Sentry")
            }}
          >
            Test Sentry
          </div>
        ),
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ]

    return routes
  }, [location, search, user.user_type])

  const router = createBrowserRouter(routes_)
  return <RouterProvider router={router} />
}
