import { AnimatePresence, motion } from "framer-motion"
import useClickOutside from "hooks/useClickOutSide"
import React, { useRef, useState } from "react"
import { ChevronDown, ChevronUp } from "react-feather"

interface RTDropDownMenuProps {
  menuOptions: React.ReactNode[]
  menuTitle?: string
  icon?: React.ReactNode
}

const RtBaseDropDownMenu = ({
  menuOptions = [],
  menuTitle = "Actions",
  icon,
}: RTDropDownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, () => closeDropdown())

  return (
    <div className="relative inline-block text-left" ref={ref}>
      <button
        type="button"
        onClick={toggleDropdown}
        className="whitespace-nowrap text-rt-violet hover:text-rt-white inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium  hover:bg-rt-violet focus:outline-none focus:ring focus:border-rt-violet transition-all duration-300"
      >
        {icon && <span className="mr-2">{icon}</span>}

        {menuTitle}

        {isOpen ? (
          <ChevronUp className="ml-2" size={16} />
        ) : (
          <ChevronDown className="ml-2" size={16} />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="dropdown"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="origin-top-right absolute right-0 mt-2 w-fit rounded-md shadow-xl bg-white z-50"
            onClick={closeDropdown}
          >
            <div className="flex flex-col items-start">
              {menuOptions.map((option, index) => (
                <React.Fragment key={index}>{option}</React.Fragment>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default RtBaseDropDownMenu
