import { motion } from "framer-motion"
import RtButton from "components/button/RtButton"
import { BaseTableProps } from "types/component-props.types"
import RowsPerPageSelector from "components/drowpdowns/RowsPerPageSelector"

function BaseTable({
  columns,
  data,
  keyExtractor,
  currentPage,
  totalPages,
  onPageChange,
  setRowsHandler,
  rowsPerPage,
}: BaseTableProps) {
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="relative overflow-x-auto">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 text-left">
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="px-4 sm:px-6 py-3 text-xs sm:text-sm font-medium text-gray-500"
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data?.map((item) => (
                <motion.tr
                  key={keyExtractor(item)}
                  className="hover:bg-gray-50"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="px-4 sm:px-6 py-4 text-xs sm:text-sm text-gray-900"
                    >
                      {column.render ? column.render(item) : item[column.key]}
                    </td>
                  ))}
                </motion.tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex items-center justify-between px-6 py-3 bg-gray-50">
            <RtButton
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 text-sm"
            >
              Previous
            </RtButton>
            <RowsPerPageSelector
              value={rowsPerPage}
              onChange={setRowsHandler}
              currentPage={currentPage}
              totalPages={totalPages}
            />
            <RtButton
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 text-sm bg-blue-500 text-white rounded disabled:bg-gray-300"
            >
              Next
            </RtButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaseTable
