import axios, { AxiosInstance } from "axios"
import { config } from "constants/global.constants"
import { showToaster } from "./show-toaster.util"
import { handleRemoveAccessToken } from "redux/features/global"
import { handLogOutUser } from "redux/features/user"
import { store } from "redux/store"

const createAPIInstance = (baseURL: string) => {
  const instance: AxiosInstance = axios.create({
    baseURL,
  })

  //response interceptor
  instance.interceptors.response.use(undefined, (err) => {
    if (err.response?.status === 401) {
      showToaster(
        "error",
        "Sorry, your session has expired. Please log in to continue."
      );
  
      store.dispatch(handleRemoveAccessToken());
      store.dispatch(handLogOutUser());
  
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
  
    return Promise.reject(err);
  });
  

  // request interceptor
  instance.interceptors.request.use((config) => {
    const accessToken = store.getState().global.accessToken
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`
    }
    return config
  })

  return instance
}

let API = createAPIInstance(config.BASE_API_URL)

export default API
