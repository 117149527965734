import { useMutation, useQueryClient } from "@tanstack/react-query"
import { showToaster } from "utils/show-toaster.util"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useRejectEvent() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({
      slug,
      formData,
    }: {
      slug: string
      formData: { reason: string }
    }) => {
      await handlePostRequest(`/admin/events/${slug}/reject`, formData)
      return { slug }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(data.slug),
      })

      showToaster("success", "The event has been rejected.", 5000)
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}
