import { ExportDropdownProps } from "types/component-props.types"
import BaseDropdown from "./BaseDropdown"
import RtButton from "components/button/RtButton"

function ExportDropdown({ onExport }: ExportDropdownProps) {
  return (
    <BaseDropdown
      placement="below"
      trigger={
        <RtButton className="px-4 py-2 bg-white text-gray-600 border rounded-md hover:bg-gray-50 focus:outline-none">
          Export
        </RtButton>
      }
    >
      <div className="py-1">
        <RtButton
          onClick={() => onExport("pdf")}
          className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 whitespace-nowrap"
        >
          Export to PDF
        </RtButton>
        <RtButton
          onClick={() => onExport("excel")}
          className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 whitespace-nowrap"
        >
          Export to Excel
        </RtButton>
        <RtButton
          onClick={() => onExport("csv")}
          className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 whitespace-nowrap"
        >
          Export to CSV
        </RtButton>
      </div>
    </BaseDropdown>
  )
}

export default ExportDropdown
