import { TicketDetails } from "types/component-props.types"
import { getTimeAgoFromNow } from "utils/date-formatter.util"

interface TicketSoldProps {
  ticket: Partial<TicketDetails>
}
function TicketsSold({ ticket }: TicketSoldProps) {
  return (
    <div className="flex justify-between py-2 border-b border-b-rt-light-gray cursor-pointer">
      <i className="text-rt-gray"> #{ticket.ticket_code}</i>
      <i className="text-rt-gray"> {ticket.ticket_group_name}</i>
      <i className="text-rt-gray">{getTimeAgoFromNow(ticket.sold_at!)}</i>
    </div>
  )
}

export default TicketsSold
