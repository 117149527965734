import { useListPlaces } from "hooks/api/useListPlaces"
import useDebounce from "hooks/useDebounce"
import { useEffect, useState } from "react"
import ReactSelect from "react-select"
import { SearchSelectProps } from "types/component-props.types"

function LocationSearchSelect({
  handleSelected,
  selectedLocation,
}: SearchSelectProps) {
  const [selectedOption, setSelectedOption] = useState(selectedLocation || null)
  const [searchTerm, setSearchTerm] = useState("")

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const onSelect = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    handleSelected(selectedOption)
  }

  const { data, isLoading } = useListPlaces(debouncedSearchTerm)

  useEffect(() => {
    if (selectedLocation) {
      setSelectedOption(selectedLocation)
    }
  }, [selectedLocation])

  return (
    <ReactSelect
      // conditionally allow value to be controlled by parent component
      {...(selectedLocation ? { value: selectedOption } : {})}
      onInputChange={(newValue) => setSearchTerm(newValue)}
      onChange={onSelect}
      options={data?.places || []}
      getOptionLabel={(option: {
        place_id: string | null
        description: string
      }) => option?.description || ""}
      isClearable
      isSearchable
      placeholder="Search for a place"
      isLoading={isLoading}
    />
  )
}

export default LocationSearchSelect
