import { useMutation } from "@tanstack/react-query"
import { handlePostRequest } from "utils/api-handler.util"

function useConfirmPayment(searchParamsObject: Record<string, any>) {
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await handlePostRequest(
        "confirm-payment",
        searchParamsObject,
      )
      return response
    },
  })

  return mutation
}

export default useConfirmPayment
