import React, { Component, ErrorInfo } from "react"
import * as Sentry from "@sentry/react"
import { Link } from "react-router-dom"
import RtButton from "components/button/RtButton"
import { isProduction } from "constants/global.constants"
import WarningIllustration from "../../assets/illustrations/warning-illustration.svg"

interface ErrorBoundaryProps {
  children?: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (isProduction) {
      Sentry.captureException(error, {
        extra: { componentStack: errorInfo.componentStack },
      })
    } else {
      console.error(error)
    }
  }

  handleRetry = () => {
    this.setState({ hasError: false })
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error occurs
      return (
        <div>
          <WarningIllustration />

          <h1>Something went wrong...</h1>
          <p>{this.state.error && this.state.error.toString()}</p>
          <p>We apologize for the inconvenience.</p>
          <div className="flex gap-5 items-center">
            <Link
              to="/"
              className="underline cursor-pointer ml-2 text-rt-violet"
            >
              Go Home
            </Link>

            <RtButton onClick={this.handleRetry}>Retry</RtButton>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
