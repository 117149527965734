import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { SalesInterface } from "types/index.types"
import { showToaster } from "./show-toaster.util"
import { getTimeAgoFromNow } from './date-formatter.util';
import { getUnixTime } from "date-fns";
import logo from "../assets/images/logo.png"
import { ADMIN } from '../constants/global.constants';


function exportToPDF(data: SalesInterface[], userType: string) {
  if (!data || data.length === 0) {
    showToaster("error", "No data available to export.")
    return
  }
  const dateToday = new Date().toLocaleString()

  const doc = new jsPDF()
  const logoX = 140;
  const logoY = 0;
  const logoWidth = 60;
  const logoHeight = 30;
  doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight)
  doc.text("Ticket Vipi Sales Report", 14, 15)

  doc.setFontSize(10)
  doc.text(`Generated: ${dateToday}`, 14, 25)
  const columns = userType === ADMIN 
  ? [
    "Ticket No",
    "Ticket Type",
    "Buyer",
    "Qty",
    "Cost (Ksh)",
    "Total Earned (Ksh)",
    "Commission (Ksh)",
    "Date/Time"
  ]
  :[
    "Ticket No",
    "Ticket Type",
    "Qty",
    "Cost (Ksh)",
    "Total Earned (Ksh)",
    "Commission (Ksh)",
    "Date/Time"
  ]

  const rows = data.map((sale) => {
    
    return userType === ADMIN 
    ? [
    `#${sale.ticket_ids.split(",")[0]}`,
    sale.ticket_type!,
    sale.buyer!,
    sale.total_tickets!,
    sale.price!,
    sale.total_price!,
    sale.total_commission!,
    getTimeAgoFromNow(sale.sold_at)!,
  ]
  :[
    `#${sale.ticket_ids.split(",")[0]}`,
    sale.ticket_type!,
    sale.total_tickets!,
    sale.price!,
    sale.total_price!,
    sale.total_commission!,
    getTimeAgoFromNow(sale.sold_at)!,
  ]})

  autoTable(doc,{
    head: [columns],
    body: rows,
    startY: 30,
    styles: { fontSize: 8 },
    headStyles: { fillColor: "#c702e4" },
    alternateRowStyles: { fillColor: "#f3f4f6" },
    theme: "grid",
  })
  const totalPages = doc.getNumberOfPages();
  console.log(totalPages)

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    const pageNumber = doc.getPageInfo(i).pageNumber
    const footerText = `Page ${pageNumber} of ${totalPages}`;

    doc.setFontSize(8);
    doc.text(footerText, 14, doc.internal.pageSize.height - 10);
  }

  doc.save(`sales_report${getUnixTime(new Date())}.pdf`)
}

export default exportToPDF