import RtTitle from "components/title/RtTitle"

const TicketCartDetails = ({ price, count, type }: any) => {
  const numericPrice = Number(
    String(price).replace("Ksh ", "").replace(/,/g, ""),
  )
  const numericCount = Number(count)
  const subtotal = numericPrice * numericCount

  const formatCurrency = (amount: number) => {
    return amount.toLocaleString()
  }

  return (
    <div className="bg-rt-light-blue bg-opacity-25 rounded-xl border-rt-blue border-[0.1rem] py-4 px-4 max-w-sm min-w-[300px] sm:min-w-[390px] h-48 flex flex-col justify-between">
      <div>{type} Ticket</div>
      <div className="flex justify-between mt-1">
        <span>{`Ksh ${formatCurrency(numericPrice)} x ${numericCount}`}</span>
        <div className="text-rt-blue font-black">
          Ksh {formatCurrency(numericPrice)}
        </div>
      </div>
      <div className="flex">
        <span className="flex-1">Ticket Price:</span>
        <span className="text-rt-blue font-black min-w-[50px] text-right">
          Ksh {formatCurrency(subtotal)}
        </span>
      </div>
    </div>
  )
}

function TicketInformation({ ticketInfo }: any) {
  const tickets = Object.keys(ticketInfo.tickets).map((ticketType) => ({
    ...ticketInfo.tickets[ticketType],
    type: ticketType,
  }))

  const totalAmount = tickets.reduce((sum, ticket) => {
    const ticketPrice = Number(
      String(ticket.price).replace("Ksh ", "").replace(/,/g, ""),
    )
    const ticketCount = Number(ticket.count)
    return sum + ticketPrice * ticketCount
  }, 0)

  return (
    <div className="inline-block sm:min-w-[450px] p-2">
      <RtTitle overrideClass="w-full mb-2">Ticket Information</RtTitle>
      <div className="w-full p-2 bg-white shadow-sm hover:shadow-md shadow-rt-dark border border-blue-300 mr-1 rounded-lg">
        <div className="p-4">
          <div className="flex flex-wrap gap-4 justify-start min-w-0">
            {tickets.map((ticket, index) => (
              <TicketCartDetails
                key={index}
                type={ticket.type}
                price={ticket.price}
                count={ticket.count}
              />
            ))}
          </div>
        </div>

        <div className="p-4 bg-blue-50">
          <div className="flex justify-between items-center">
            <span className="text-lg font-semibold">Total Amount:</span>
            <span className="text-2xl font-bold text-blue-600">
              Ksh {totalAmount.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketInformation
