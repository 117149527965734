import { getInitials } from "utils/formatter.util"

function RtAvatar({ name }: { name: string }) {
  const userInitials = getInitials(name)

  return (
    <div className="w-14 h-14 rounded-full bg-rt-white-smoke text-rt-blue font-bold font-poppins text-lg flex items-center justify-center">
      {userInitials}
    </div>
  )
}

export default RtAvatar
