import { ADMIN, HOST } from "constants/global.constants"
import { store } from "redux/store"

export function isAdmin() {
  if (!isAuthenticated()) return false

  const user = store.getState().user

  return user.user_type === ADMIN
}

export function isHost() {
  if (!isAuthenticated()) return false

  const user = store.getState().user
  return user.user_type === HOST
}

export function isEventHost(hostId: number) {
  if (!isAuthenticated()) return false

  if (isAdmin()) return true

  const user = store.getState().user

  return user.id === hostId
}

export function isAuthenticated() {
  const global = store.getState().global

  return !!global.accessToken
}

export function getAuthUserType(): string | null {
  if (!isAuthenticated()) return null

  return store.getState().user.user_type
}

export function canHostEvent() {
  return isAuthenticated()
}

export function canViewMyTickets() {
  return isAuthenticated() && !isAdmin()
}

export function canViewMyEvents() {
  if (!isAuthenticated) return false

  return isHost()
}
