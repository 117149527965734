function RtImageDisplay({
  imageUrl,
  title,
  overrideImageContainer = "",
  overrideTitleStyle = "",
  overrideImageStyle = "",
  overrideImageWrapper = "",
}: {
  imageUrl: string
  title?: string
  overrideImageContainer?: string
  overrideTitleStyle?: string
  overrideImageWrapper?: string
  overrideImageStyle?: string
}) {
  return (
    <div className={`flex flex-col ${overrideImageContainer}`}>
      {title && (
        <p
          className={`text-xs/[15px] text-rt-dark-blue font-light ${overrideTitleStyle}`}
        >
          {title}
        </p>
      )}
      <div
        className={`flex justify-center bg-gray-100 mt-2 ${overrideImageWrapper}`}
      >
        <img
          src={imageUrl}
          alt={title ?? 'Example image'}
          className={`w-52 h-52 rounded-t-md ${overrideImageStyle}`}
        />
      </div>
    </div>
  )
}

export default RtImageDisplay
