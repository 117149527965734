import React, { useState, useRef, useEffect } from "react"
import { BaseDropdownProps } from "types/component-props.types"

interface EnhancedBaseDropdownProps extends BaseDropdownProps {
  placement?: "auto" | "above" | "below" // Dropdown position
}

function BaseDropdown({
  trigger,
  children,
  className = "",
  placement = "auto",
}: EnhancedBaseDropdownProps) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !dropdownRef.current?.contains(event.target as Node) &&
        !triggerRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  const placementStyles =
    placement === "above" ? "top-0 -translate-y-full" : "top-full mt-2"

  return (
    <div className={`relative inline-block ${className}`} ref={triggerRef}>
      <div onClick={toggleDropdown}>{trigger}</div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className={`absolute right-0 bg-white border border-gray-300 rounded-md shadow-lg z-50 ${placementStyles}`}
        >
          {React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child as React.ReactElement<any>, {
                  onClick: () => setIsOpen(false),
                })
              : child,
          )}
        </div>
      )}
    </div>
  )
}

export default BaseDropdown
