import ApproveEventModal from "components/modal/ApproveEventModal"
import RejectEventModal from "components/modal/RejectEventModal"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import { useMarkEventAsComplete } from "hooks/api/useMarkEventAsComplete"
import CancelEventModal from "components/modal/CancelEventModal"
import useDisclosure from "hooks/useDisclosure"
import RtButton from "components/button/RtButton"
import { showToaster } from "utils/show-toaster.util"

interface AdminEventActionProps {
  slug: string
  permissions: {
    canApproveEvent: boolean
    canCancelEvent: boolean
    canMarkEventAsComplete: boolean
  }
  overrideClass?: string
  isKycApproved: boolean
}

function AdminEventAction({
  slug,
  permissions,
  overrideClass,
  isKycApproved,
}: AdminEventActionProps) {
  const { isOpen: isApproveModalOpen, onToggle: toggleApproveModal } =
    useDisclosure()

  const { isOpen: isRejectModalOpen, onToggle: toggleRejectModal } =
    useDisclosure()

  const { isOpen: isCancelModalOpen, onToggle: toggleCancelModal } =
    useDisclosure()

  const completeEventQueryClient = useMarkEventAsComplete(slug)

  return (
    <div className={overrideClass}>
      {/* <RtBaseDropDownMenu menuOptions={menuOptions} menuTitle="Event Menu" /> */}
      <div className="flex justify-between">
        {permissions.canApproveEvent && (
          <>
            <RtButton
              variant="link"
              onClick={() => {
                if (!isKycApproved) {
                  showToaster(
                    "error",
                    "Please approve kyc before approving the event",
                  )
                  return
                }
                toggleApproveModal()
              }}
              overrideClass="text-rt-green border-rt-green"
            >
              Approve event
            </RtButton>

            <RtButton
              variant="link"
              onClick={() => {
                if (!isKycApproved) {
                  showToaster(
                    "error",
                    "Please approve kyc before rejecting the event",
                  )
                  return
                }
                toggleRejectModal()
              }}
              overrideClass="text-rt-red border-rt-red"
            >
              Reject event
            </RtButton>
          </>
        )}
      </div>

      <div className={"flex justify-between mt-4"}>
        {permissions.canMarkEventAsComplete && (
          <RtButton
            variant="link"
            onClick={() => completeEventQueryClient.mutate()}
          >
            Mark event as complete
          </RtButton>
        )}

        {permissions.canCancelEvent && (
          <RtButton variant="link" onClick={toggleCancelModal}>
            Cancel event
          </RtButton>
        )}
      </div>

      {isApproveModalOpen && (
        <ApproveEventModal
          isOpen={isApproveModalOpen}
          onClose={toggleApproveModal}
          slug={slug}
        />
      )}

      {isRejectModalOpen && (
        <RejectEventModal
          isOpen={isRejectModalOpen}
          onClose={toggleRejectModal}
          slug={slug}
        />
      )}

      {isCancelModalOpen && (
        <CancelEventModal
          isOpen={isCancelModalOpen}
          onClose={toggleCancelModal}
          slug={slug}
        />
      )}

      {completeEventQueryClient.isPending && (
        <ActionLoadingModal
          loadingText="Please wait as your event is being marked as complete..."
          isOpen={completeEventQueryClient.isPending}
        />
      )}
    </div>
  )
}

export default AdminEventAction
