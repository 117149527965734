import { useNavigate } from "react-router-dom";

function FAQPage() {
  const navigate = useNavigate();

  const faqs = [
    {
      question: "What is Ticket Vipi?",
      answer:
        "Ticket Vipi is an online ticketing platform for events happening in Kenya and beyond.",
    },
    {
      question: "How do I create an account?",
      answer: (
        <>
          Click{" "}
          <span
            className="text-blue-600 underline cursor-pointer"
            onClick={() => navigate("/sign-up")}
          >
            Sign Up
          </span>{" "}
          on the homepage and follow the instructions to register.
        </>
      ),
    },
    {
      question: "How do I purchase tickets?",
      answer:
        "Browse events, select tickets, and complete the payment process.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "Visa, MasterCard, MPESA, and other supported payment methods.",
    },
    {
      question: "Can I cancel or exchange tickets?",
      answer:
        "Tickets are generally non-refundable unless specified by the event organizer.",
    },
    {
      question: "How will I receive my tickets?",
      answer: "Via email or accessible in your account.",
    },
    {
      question: "How can I contact support?",
      answer: (
        <>
          Email:{" "}
          <a
            href="mailto:support@ticketvipi.com"
            className="text-blue-600 underline"
          >
            support@ticketvipi.com
          </a>
          , Phone:{" "}
          <a href="tel:+254112130196" className="text-blue-600 underline">
            +254 112 130 196
          </a>
          .
        </>
      ),
    },
  ];

  const handleClose = () => {
    navigate("/"); // Navigate back to the home page
  };

  const handleOkay = () => {
    navigate("/sign-up"); // Navigate to the sign up page
  };

  return (
    <div className="max-w-6xl mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-8">Frequently Asked Questions</h1>
      
      <div className="flex">
        <div className="flex-1">
          {/* Scrollable FAQ section */}
          <div className="h-[60vh] overflow-y-auto pr-4 mb-6">
            {faqs.map((faq, index) => (
              <div key={index} className="p-4 border-b border-gray-300">
                <h2 className="font-semibold text-lg">{faq.question}</h2>
                <p className="mt-2 text-gray-700">{faq.answer}</p>
              </div>
            ))}
          </div>

          {/* Fixed buttons below scroll area */}
          <div className="flex justify-between px-32">
            <button
              onClick={handleClose}
              className="px-4 py-2 bg-rt-violet text-white rounded-md hover:bg-opacity-90 transition-colors"
            >
              Go Back
            </button>
            <button
              onClick={handleOkay}
              className="px-4 py-2 bg-rt-violet text-white rounded-md hover:bg-opacity-90 transition-colors"
            >
              Continue
            </button>
          </div>
        </div>
        
        {/* Scroll bar indicator */}
        <div className="w-2 ml-4 bg-gray-200 rounded-full"/>
      </div>

    </div>
  );
}

export default FAQPage;
