import { useMutation, useQueryClient } from "@tanstack/react-query"
import { showToaster } from "utils/show-toaster.util"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useRejectKyc() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({
      hostId,
      data,
    }: {
      hostId: number
      data: { reject_reason: string }
    }) => {
      await handlePostRequest(`/admin/${hostId}/reject-kyc`, data)
      return hostId
    },
    onSuccess: (hostId) => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.viewKyc(hostId),
      })

      showToaster("success", "You have rejected this kyc", 5000)
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}

export function useApproveKyc() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (hostId: number) => {
      await handlePostRequest(`/admin/${hostId}/approve-kyc`, {})
      return hostId
    },
    onSuccess: (hostId) => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.viewKyc(hostId),
      })

      showToaster("success", "You have successfully approved this kyc", 5000)
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}


