import { useDispatch } from "react-redux"
import { addError } from "redux/features/formErrors"

interface IError {
  field: string
  message: string
  rule?: string
}

/**
 * Custom hook that provides a function to set validation errors in the Redux store.
 *
 * @returns {Function} The function to set validation errors.
 */
function useSetValidationErrors() {
  const dispatch = useDispatch()

  function setValidationErrors(errorMessages: IError[]) {
    if (errorMessages.length === 0) return

    errorMessages.forEach((error) => {
      dispatch(
        addError({ fieldName: error.field, errorMessage: error.message }),
      )
    })
  }

  return setValidationErrors
}

export { useSetValidationErrors }
