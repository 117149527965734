import { RtGridProps } from "types/component-props.types"
import { cn } from "utils/cn.util"

function RtGrid({ children, overrideClass }: RtGridProps) {
  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8",
        overrideClass,
      )}
    >
      {children}
    </div>
  )
}

export default RtGrid
