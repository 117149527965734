import { Download } from "react-feather"
import BaseModal from "./BaseModal"
import { TicketQrCodeModalProps } from "types/component-props.types"
import useFetchTicketQrCode from "hooks/api/useFetchTicketQrCode"
import { showToaster } from "utils/show-toaster.util"
import Spinner from "components/loader/Spinner"
import { formatDateToDisplay } from "utils/date-formatter.util"
import { getFormattedLocaleTime } from "../../utils/date-formatter.util"

function TicketQrCodeModal({
  isOpen,
  onClose,
  ticketDetails,
}: TicketQrCodeModalProps) {
  if (!isOpen) return null

  const { isLoading, isError, data } = useFetchTicketQrCode({
    ticket_code: ticketDetails.ticket_code,
    event_slug: ticketDetails.event_slug,
  })

  if (isError) {
    showToaster(
      "error",
      "An error occurred while retrieving the ticket QR code",
      4000,
    )
    onClose()
    return
  }

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-full mx-auto p-6">
          {/* QR Code and Details */}
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex flex-col items-center space-y-4">
              <div className="bg-gradient-to-r from-purple-600/10 to-rt-violet/10 p-4 rounded-2xl">
                <img
                  src={data.qrcode}
                  alt="Ticket QR Code"
                  className="w-full max-w-[400px] mx-auto"
                />
              </div>
            </div>

            <div className="space-y-2">
              <TicketDetail
                label="Ticket Purchase Date"
                value={`${formatDateToDisplay(
                  ticketDetails.sold_at,
                )} at ${getFormattedLocaleTime(ticketDetails.sold_at)}`}
              />
              <TicketDetail
                label="Ticket Group"
                value={ticketDetails.ticket_group_name}
              />

              <TicketDetail
                label="Price"
                value={ticketDetails.price}
                isHighlighted={true}
              />
            </div>
          </div>

          {/* Download Ticket */}
          {data.ticket_url && (
            <div className="mt-4 text-center">
              <a
                href={data.ticket_url}
                className="inline-flex px-4 py-3 text-sm flex-row justify-center items-center rounded-md transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-purple-600 from-10% to-rt-violet to-90% text-white font-semibold"
                download
              >
                <Download size={18} className="mr-2" />
                Download Ticket
              </a>
            </div>
          )}
        </div>
      )}
    </BaseModal>
  )
}

function TicketDetail({
  label,
  value,
  isHighlighted = false,
}: {
  label: string
  value: string | number
  isHighlighted?: boolean
}) {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-sm font-medium text-gray-500">{label}</span>
      <span
        className={`text-base font-semibold ${
          isHighlighted ? "text-primary" : "text-gray-800"
        }`}
      >
        {value}
      </span>
    </div>
  )
}

export default TicketQrCodeModal
