import { createSlice } from "@reduxjs/toolkit"

interface FormErrors {
  [key: string]: string
} 

const initialState: FormErrors = {}

const formErrorsSlice = createSlice({
  name: "formErrors",
  initialState,
  reducers: {
    addError: (state, action) => {
      const { fieldName, errorMessage } = action.payload

      return {
        ...state,
        [fieldName]: errorMessage,
      }
    },
    clearError: (state, action) => {
      const newState = { ...state }
      delete newState[action.payload.fieldName]

      return newState
    },
    clearAllValidationErrors: () => {
      return {}
    },
  },
})

export const { addError, clearError, clearAllValidationErrors } =
  formErrorsSlice.actions

export const formErrorsReducer = formErrorsSlice.reducer
