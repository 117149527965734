import BaseModal from "components/modal/BaseModal"
import { Html5QrcodeScanner } from "html5-qrcode"
import { useEffect } from "react"
import { RTQrScannerProps } from "types/component-props.types"

const qrcodeElementId = "html5qr-code-modal"

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props: RTQrScannerProps) => {
  let config: any = {}
  if (props.fps) {
    config.fps = props.fps || 10
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip
  }
  return config
}

function RTQrScanner(props: RTQrScannerProps) {
  useEffect(() => {
    if (!props.showScanner) return
    // when component mounts
    const config = createConfig(props)

    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeElementId,
      { rememberLastUsedCamera: true, ...config },
      false,
    )

    html5QrcodeScanner.render(
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback,
    )

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error)
      })
    }
  }, [props])

  return (
    <BaseModal
      isOpen={props.showScanner}
      onClose={props.toggleScanner}
      overrideClass="p-0"
    >
      <div id={qrcodeElementId} />
    </BaseModal>
  )
}

export default RTQrScanner
