import ErrorAnimation from "components/error-animation"
import SuccessAnimation from "components/success-animation"
import { useGetLoggedInUser } from "hooks/api/useGetLoggedInUser"
import { useDispatch } from "react-redux"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { handLogInUser } from "redux/features/user"
import { createUserType } from "utils/create-user-type"

function EmailVerified() {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const status = searchParams.get("status") ?? "error"
  const message = searchParams.get("message") ?? "An error occurred"
  const isSuccessful = status === "success"
  const dispatch = useDispatch()

  const { data, isError, isSuccess } = useGetLoggedInUser(isSuccessful)

  if (isSuccess) {
    const user = data?.data

    const user_type = createUserType(user)

    dispatch(handLogInUser({ ...user, user_type, test: true }))

    navigate("/")
  }

  if (isError) {
    navigate("/login")
  }

  return (
    <div className="m-5 flex flex-col items-center gap-2">
      {isSuccessful ? (
        <>
          <SuccessAnimation />
          <div className="text-center font-bold text-rt-green">
            <p>{message}</p>
          </div>
        </>
      ) : (
        <>
          <ErrorAnimation />
          <div className="text-center font-bold text-rt-red">
            <p>{message}</p>
          </div>
        </>
      )}
      <Link to="/" className="underline cursor-pointer ml-2 text-rt-violet">
        Go Home
      </Link>
    </div>
  )
}

export default EmailVerified
