import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearCart } from "redux/features/cart"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useDropTickets() {
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const mutation = useMutation({
    mutationFn: async ({ slug, lock_identifier }: 
      { 
        slug: string; 
        lock_identifier: string;
        checkout: boolean,
        refetch: () => void
      }) => {
      const response = await handlePostRequest(
        `/events/${slug}/drop-tickets`,
        { lock_identifier },
      )

      return response
    },
    onSuccess: (_, {checkout, refetch }) => {
    showToaster("success", "You have successfully cleared your tickets")
      dispatch(clearCart())
      if (checkout){
        navigate(-1)
        return
      }
      refetch()
    },

    onError: (err: any) => {
      handleError(err)
    },
  })

  return mutation
}
