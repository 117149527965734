import { useState } from "react"

/**
 * Custom hook for managing disclosure state.
 * @returns An object containing the `isOpen`, `onOpen`, `onClose`, and `onToggle` functions.
 */
/**
 * Custom hook for managing a disclosure state.
 *
 * @param initialState - The initial state of the disclosure. Default is `false`.
 * @returns An object containing the disclosure state and functions to control it.
 */
function useDisclosure(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState)

  /**
   * Opens the disclosure.
   */
  const onOpen = () => setIsOpen(true)

  /**
   * Closes the disclosure.
   */
  const onClose = () => setIsOpen(false)

  /**
   * Toggles the disclosure state.
   */
  const onToggle = () => setIsOpen(!isOpen)

  return { isOpen, onOpen, onClose, onToggle }
}

export default useDisclosure
