import { useNavigate } from 'react-router-dom';
import { twMerge } from "tailwind-merge"

interface DashboardStatCardProps {
  stat: any
  statDisplayName: any
  to: string
  overrideContainerClass?: string
}

const DashboardStatCard: React.FC<DashboardStatCardProps> = ({
  stat,
  statDisplayName,
  to,
  overrideContainerClass,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(to)
  }

  return (
    <div
      className={twMerge(
        `bg-rt-light-blue bg-opacity-20 flex flex-col items-center justify-center py-5 px-3 rounded-lg shadow cursor-pointer ${overrideContainerClass}`,
      )}
      onClick={handleClick}
    >
      <h5 className="font-semibold mb-2.5 font-poppins whitespace-nowrap">
        {stat}
      </h5>
      <p className="text-sm text-rt-gray font-poppins whitespace-nowrap">
        {statDisplayName}
      </p>
    </div>
  )
}

export default DashboardStatCard;
