import { useMutation } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

function useResendEmailVerificationLink() {
  return useMutation({
    mutationFn: async () => {
      await handlePostRequest("resend-verification-mail", {})
    },
    onSuccess: () => {
      showToaster(
        "success",
        "We've sent a verification email to your inbox. Please click the link in the email to verify your email address. Thank you! ",
        5000,
      )
    },
    onError: (error) => {
      handleError(error)
    },
  })
}

export default useResendEmailVerificationLink
