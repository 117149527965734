import { handleGetRequest } from "utils/api-handler.util"
import { useQuery, useQueryClient } from "@tanstack/react-query"

export function useListSoldTickets(page: number, limit: number, userId:string) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [`listSoldTickets${userId}`, page, limit],
    queryFn: async () =>
      await handleGetRequest("/tickets/sold", { page, limit }),
    staleTime: 1000 * 60 * 1,
    placeholderData: (previousData) => 
      queryClient.getQueryData([`listSoldTickets${userId}`, page, limit]) || previousData,
  });
}