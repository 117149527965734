import { Calendar, Clock, DollarSign, MapPin } from "react-feather"
import { Link } from "react-router-dom"
import { TicketPreviewProps } from "types/component-props.types"
import {
  formatDateToDisplay,
  getFormattedLocaleTime,
} from "utils/date-formatter.util"

function TicketPreview({
  ticketType,
  event,
  linkTo,
  children,
}: TicketPreviewProps) {
  return (
    <div className="bg-white w-full py-2 px-2 flex flex-col gap-y-1 rounded-lg border border-rt-light-blue text-sm">
      <p className="text-xs/[15px] text-rt-violet">{ticketType}</p>

      {linkTo ? (
        <Link to={linkTo}>
          <p className="text-md">{event?.name || event?.event_name}</p>
        </Link>
      ) : (
        <p className="text-md">{event?.name || event?.event_name}</p>
      )}

      <div className="flex flex-row ">
        <Calendar
          className=" text-rt-blue mr-1 pr-1"
          size={16}
          strokeWidth={1}
        />
        <p className="text-xs/[15px] text-rt-gray font-light">
          {formatDateToDisplay(event.start_date!)} {"-"}{" "}
          {formatDateToDisplay(event.end_date!)}
        </p>
      </div>

      <div className="flex flex-row ">
        <Clock className=" text-rt-blue mr-1 pr-1" size={16} strokeWidth={1} />
        <p className="text-xs/[15px] text-rt-gray font-light">
          {getFormattedLocaleTime(event.start_date!)}
        </p>
      </div>

      <div className="flex flex-row">
        <DollarSign
          className=" text-rt-blue mr-1 pr-1"
          size={16}
          strokeWidth={1}
        />
        <p className="text-xs/[15px] text-rt-gray font-light">{event?.price}</p>
      </div>

      <div className="flex flex-row">
        <MapPin className=" text-rt-blue mr-1 pr-1" size={16} strokeWidth={1} />
        <p className="text-xs/[15px] text-rt-gray font-light">
          {event?.location_name || event?.event_location || "-"}
        </p>
      </div>

      {children}
    </div>
  )
}

export default TicketPreview
