import useRtSetUrlParams from "hooks/useRtSetUrlParams"

const ticketFilterTabs = ["Current", "Past"]

function MyTicketTabs() {
  // set is active tab using searchParams
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()

  const activeTab = searchParams.get("tab")

  const handleChangeTab = (tab: string) => {
    handleSetUrlParams({ tab })
  }

  return (
    <div className="w-full bg-rt-white  flex justify-evenly">
      {ticketFilterTabs.map((tab, idx) => (
        <button
          key={`my-ticket-tab-${idx}`}
          onClick={() => handleChangeTab(tab)}
          className={`p-2 py-4 w-full font-semibold   ${
            activeTab?.toLocaleLowerCase() === tab.toLocaleLowerCase()
              ? "border-b-2 border-b-rt-blue text-rt-blue bg-rt-light-blue"
              : "text-rt-gray"
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  )
}

export default MyTicketTabs
