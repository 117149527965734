import { ReactNode, useEffect } from "react"
import { useNavigate } from "react-router-dom"

interface ProtectedRouteProps {
  children: ReactNode
  can: boolean
}
export function ProtectedRoute({ children, can }: ProtectedRouteProps) {
  const navigate = useNavigate()

  useEffect(() => {
    if (!can) navigate("/")
  }, [])

  if (can) return children

  return null
}
