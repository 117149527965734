import { useQuery } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { clearCart } from "redux/features/cart"
import { handleError, handleGetRequest } from "utils/api-handler.util"

export function useGetPreCheckoutData(lockId: string | undefined) {
  const dispatch = useDispatch()
  return useQuery({
    enabled: !!lockId,
    queryKey: ["preCheckout", lockId],
    queryFn: async () =>
      await handleGetRequest(`/pre-checkout`, { lock_identifier: lockId }),
    throwOnError: (error) => {
      handleError(error)

      dispatch(clearCart())

      return false
    },
  })
}
