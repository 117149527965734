import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"
import { SalesPieChartProps } from "types/component-props.types"
import { cn } from "utils/cn.util"

function SalesPieChart({
  overrideClass,
  dataSeries,
  labels,
}: SalesPieChartProps) {
  const options: ApexOptions = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: labels || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "right",
          },
        },
      },
    ],
  }
  return (
    <div id="pie-chart" className={cn("bg-rt-white p-4 ", overrideClass)}>
      <p className="text-rt-blue font-semibold">
        Sales Broke Up By Tickets Type
      </p>
      <ReactApexChart
        options={options}
        series={dataSeries || []}
        type="pie"
        width={"400px"}
      />
    </div>
  )
}

export default SalesPieChart
