import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useUpdateTicketGroup(slug: string, ticketGroupID: number) {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (formData: any) => {
      const response = await handlePostRequest(
        `/events/${slug}/ticket-group/${ticketGroupID}/update`,
        { ...formData },
      )
      return response
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(slug),
      })
    },
    onError: (err: any) => {
      handleError(err)
    },
  })

  return mutation
}
