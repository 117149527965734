import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { clearAllValidationErrors } from "redux/features/formErrors";
import { updateUser } from "redux/features/user";
import { handleError, handlePutRequest } from "utils/api-handler.util";
import { showToaster } from "utils/show-toaster.util";
import { useSetValidationErrors } from "hooks/useSetFormErrors";

// Define options type for the hook
interface UseEditProfileOptions {
  onSuccess?: () => void;
}

// Custom hook for editing profile with an optional onSuccess callback
export function useEditProfile({ onSuccess }: UseEditProfileOptions = {}) {
  const dispatch = useDispatch();
  const setFormErrorsToStore = useSetValidationErrors();

  const mutation = useMutation({
    mutationFn: async (profileData: any) => {
      const response = await handlePutRequest("/profile-edit", profileData);
      return response;
    },
    onSuccess: (response) => {
      const updatedUser = response.data.data;
      dispatch(updateUser(updatedUser));
      dispatch(clearAllValidationErrors());

      // Show success message
      showToaster("success", "Your profile has been updated successfully");

      // Call the provided onSuccess callback if it exists
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (err: any) => {
      handleError(err);
      const errorMessages = err?.response?.data?.original_error?.messages?.errors || [];
      setFormErrorsToStore(errorMessages);
    },
  });

  return mutation;
}
