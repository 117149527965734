import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import PageNav from "components/nav/PageNav"
import { ACTIVE_EVENTS } from "constants/global.constants"
import HostEventsList from "pages/my-events/host-event-list"
import { useParams } from "react-router-dom"

function HostProfile() {
  const { hostId } = useParams()

  console.log(hostId)

  return (
    <div>
      <SectionContainer>
        <PageNav>Host Info</PageNav>

        <ContentContainer overrideClass="text-center">
          <div className="flex flex-col">
            <img
              src="https://via.placeholder.com/150"
              alt="host"
              className="w-24 h-24 rounded-full mx-auto mt-4 border border-rt-blue"
            />
            <span className="font-semibold">Country folks</span>
            <span className="font-semibold text-rt-violet">Host</span>
          </div>

          <p className="text-rt-gray mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor
            sapiente culpa repudiandae repellat et sed accusamus atque deserunt
            fugit nam explicabo ut delectus ullam, quis voluptas veritatis harum
            quibusdam excepturi.
          </p>
        </ContentContainer>

        <HostEventsList isActive={ACTIVE_EVENTS} listStyle="grid" />
      </SectionContainer>
    </div>
  )
}

export default HostProfile
