import NoSearchFoundSvg from "../../assets/illustrations/search-illustration.svg"

interface NoItemsFallbackProps {
  title: string
}

function NoItemsFallback({ title }: NoItemsFallbackProps) {
  return (
    <div className="flex flex-col items-center gap-4">
      <NoSearchFoundSvg />
      <p className="text-rt-gray text-lg text-center">{title}</p>
    </div>
  )
}

export default NoItemsFallback
