import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { ApproveEventModalProps } from "types/component-props.types"
import { useApproveEvent } from "hooks/api/useApproveEvent"
import ActionLoadingModal from "./ActionLoadingModal"

function ApproveEventModal({ isOpen, onClose, slug }: ApproveEventModalProps) {
  const approvalQueryClient = useApproveEvent()

  const onApprove = () => {
    onClose()
    approvalQueryClient.mutate(slug)
  }

  return (
    <>
      <BaseModal isOpen={isOpen} onClose={onClose}>
        <p className="text-center">
          Are you sure you want to approve this event?
        </p>

        <div className="mt-8 w-full flex justify-between border-t border-rt-light-gray pt-2">
          <RtButton
            onClick={onClose}
            variant="outline"
            overrideClass="border border-rt-red"
            textOverrideClass="text-rt-red"
          >
            Cancel
          </RtButton>
          <RtButton variant="solid" onClick={onApprove}>
            Approve
          </RtButton>
        </div>
      </BaseModal>
      <ActionLoadingModal
        loadingText="Event approval is being processed..."
        isOpen={approvalQueryClient.isPending}
      />
    </>
  )
}

export default ApproveEventModal
