import "../../assets/styles/error-animation.scss"

function ErrorAnimation() {
  return (
    <div className="error-animation">
      <svg
        className="crossmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="crossmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="crossmark__cross"
          fill="none"
          d="M16 16 36 36 M36 16 16 36"
        />
      </svg>
    </div>
  )
}

export default ErrorAnimation
