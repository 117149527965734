import SectionContainer from "components/layout/SectionContainer"
import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { useState } from "react"
import RtTextArea from "components/input/RtTextArea"
import { CancelEventModalProps } from "types/component-props.types"
import ActionLoadingModal from "./ActionLoadingModal"
import { useCancelEvent } from "hooks/api/useCancelEvent"

function CancelEventModal({ isOpen, onClose, slug }: CancelEventModalProps) {
  const [cancel_reason, setCancelReason] = useState({ cancel_reason: "" })

  const cancelEventQueryClient = useCancelEvent(slug, cancel_reason)

  const onReject = () => {
    onClose()
    cancelEventQueryClient.mutate()
  }

  const onInputChange = (value: any) => {
    setCancelReason(value)
  }

  return (
    <>
      <ActionLoadingModal
        loadingText="Please wait as the event is being cancelled..."
        isOpen={cancelEventQueryClient.isPending}
      />
      <BaseModal isOpen={isOpen} onClose={onClose}>
        <SectionContainer overrideClass="font-poppins">
          <p className="mb-2">Are you sure you want to cancel this event?</p>

          <RtTextArea
            inputName="cancel_reason"
            label="Cancel Reason"
            isRequired
            labelOverrideClass="text-rt-gray"
            inputPlaceholder="Please provide a reason for cancelling the event"
            onInputChange={onInputChange}
          />

          <div className="mt-8 flex justify-between">
            <RtButton
              onClick={onClose}
              variant="outline"
              overrideClass="border border-rt-red"
              textOverrideClass="text-rt-red"
            >
              Cancel
            </RtButton>
            <RtButton variant="solid" onClick={onReject}>
              Continue
            </RtButton>
          </div>
        </SectionContainer>
      </BaseModal>
    </>
  )
}

export default CancelEventModal
