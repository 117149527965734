// import FeaturedEvents from "./featured-events"
import BrowseCategory from "./browse-category"
import RtSearch from "components/input/RtSearch"
import { useListEvents } from "hooks/api/useListEvents"
import Spinner from "components/loader/Spinner"
import SectionContainer from "components/layout/SectionContainer"
// import useListFeaturedEvents from "hooks/api/useListFeaturedEvents"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import PeriodFilter from "pages/admin-dashboard/period-filter"
import useDebounce from "hooks/useDebounce"
import FiltersLayout from "components/responsive-layouts/FiltersLayout"
import RtButton from "components/button/RtButton"
import { useEffect, useRef } from "react"
import EventCard from "components/event-card/EventCard"
import { EventInterFace } from "types/index.types"
import RtGrid from "components/grid/RtGrid"
import { useNavigate } from "react-router-dom"
import useScrollIntoView from "hooks/useScrollIntoView"
import getPeriodDisplay from "utils/getPeriodDisplay.util"
import { PERIODS, TPeriodValue } from "constants/global.constants"
import NoItemsFallback from "components/fallback/NoItemsFallback"

export default function Home() {
  const nextPageSpinnerRef = useRef(null)
  useScrollIntoView(nextPageSpinnerRef)

  const navigate = useNavigate()
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const q = searchParams.get("q")
  const start_date = searchParams.get("start_date")
  const end_date = searchParams.get("end_date")
  const category_id = searchParams.get("category_id")
  const period = (searchParams.get("period") ||
    PERIODS.UPCOMING.value) as TPeriodValue
  const debouncedSearch = useDebounce(q, 500)

  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useListEvents({
      start_date: start_date ?? null,
      end_date: end_date ?? null,
      q: debouncedSearch,
      category_id,
      period,
    })

  // const { isLoading: isFeaturedLoading, data: _data } = useListFeaturedEvents(
  //   {},
  // )

  // const hasFeaturedEvents = _data?.data?.length
  // const showFeaturedEvents = !q && hasFeaturedEvents

  const noEvents = data?.pages[0].data.data.length === 0

  const eventListTitle = getPeriodDisplay(period)

  useEffect(() => {
    if (!searchParams.get("period")) {
      handleSetUrlParams({ period: PERIODS.UPCOMING.value })
    }
  }, [period])

  return (
    <>
      <SectionContainer>
        <FiltersLayout>
          <div className="flex justify-between items-center gap-4">
            <RtSearch
              handleSearch={(value) => handleSetUrlParams({ q: value })}
              searchValue={q || ""}
            />

            <PeriodFilter />
          </div>
        </FiltersLayout>
      </SectionContainer>

      {/* {isFeaturedLoading ? <Spinner /> : <></>} */}

      {/* {showFeaturedEvents ? <FeaturedEvents events={_data?.data} /> : <></>} */}

      <BrowseCategory />

      <SectionContainer sectionTitle={`${eventListTitle} events`}>
        {isLoading && <Spinner />}
        <RtGrid>
          {noEvents ? (
            <NoItemsFallback title=" There are no events for this period..." />
          ) : (
            data?.pages?.map((pages) => (
              <>
                {pages?.data?.data.map((event: EventInterFace, idx: number) => (
                  <EventCard
                    key={idx}
                    event={event}
                    handleClickCard={() => navigate(`/events/${event.slug}`)}
                  />
                ))}
              </>
            ))
          )}
        </RtGrid>
      </SectionContainer>

      <SectionContainer overrideClass=" flex justify-center">
        {isFetchingNextPage && (
          <div ref={nextPageSpinnerRef} className="h-48 m-auto">
            <Spinner />
          </div>
        )}

        {!isFetchingNextPage && hasNextPage && (
          <RtButton
            id="more"
            overrideClass="w-[8rem]"
            onClick={() => fetchNextPage()}
          >
            More events
          </RtButton>
        )}
      </SectionContainer>
    </>
  )
}
