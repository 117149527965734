import { useListCategories } from "hooks/api/useListCategories"
import { useEffect, useState } from "react"
import ReactSelect from "react-select"
import { CategorySearchSelectProps } from "types/component-props.types"

function CategoriesSelect({
  handleSelected,
  selectedCategory,
}: CategorySearchSelectProps) {
  const [selectedOption, setSelectedOption] = useState(selectedCategory || null)
  const { data, isLoading } = useListCategories()

  const onSelect = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    handleSelected(selectedOption)
  }

  useEffect(() => {
    if (selectedCategory) {
      setSelectedOption(selectedCategory)
    }
  }, [selectedCategory])
  return (
    <ReactSelect
      {...(selectedCategory ? { value: selectedOption } : {})}
      onChange={onSelect}
      options={data?.data || []}
      getOptionLabel={(option: { id: number | null; name: string }) =>
        option?.name || ""
      }
      isClearable
      isSearchable
      placeholder="Select category"
      isLoading={isLoading}
    />
  )
}

export default CategoriesSelect
