import RtButton from "components/button/RtButton"
import NoSearchFoundSvg from "../../assets/illustrations/404-illustration.svg"
import { useNavigate } from "react-router-dom"

function PageNotFound() {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center justify-center gap-8 w-full h-screen">
      <NoSearchFoundSvg />
      <p className="text-rt-gray text-lg">Oops! Sorry Page not Found...</p>
      <RtButton onClick={() => navigate("/")}>Home</RtButton>
    </div>
  )
}

export default PageNotFound
