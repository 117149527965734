import { useMutation } from "@tanstack/react-query"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

export function useForgotPassword(handleSuccess?: () => void) {
  const setFormErrorsToStore = useSetValidationErrors()

  const mutation = useMutation({
    mutationFn: async (formData: { email: string }) => {
      const response = await handlePostRequest("/forgot-password", formData)
      return response
    },
    onSuccess: () => {
      handleSuccess && handleSuccess()
      showToaster(
        "success",
        "An email has been sent to your inbox, please click the link in the email to reset your password.",
        4000,
      )
    },
    onError: (err: any) => {
      const errorMessages =
        err?.response.data?.original_error.messages.errors || []

      setFormErrorsToStore(errorMessages)
      showToaster("error", "An error occurred while resetting your password")
    },
  })

  return mutation
}
