import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import rootReducer from "./rootReducer"
import { APP_ENVIRONMENT } from "constants/global.constants"

const persistConfig = {
  key: "root",
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: APP_ENVIRONMENT === "development",
})

export const persistor = persistStore(store)
