import SectionContainer from "components/layout/SectionContainer"
import DashboardStatCard from "./dashboard-stat-card"
import {
  useGetDashboardData,
  useGetDashboardEvents,
} from "hooks/api/useDashboard"
import Spinner from "components/loader/Spinner"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import ErrorFallback from "components/fallback/ErrorFallback"
import PeriodFilter from "./period-filter"
import removeFalsyValues from "utils/remove-falsy.util"
import AdminEventLink from "./admin-event-link"
import { EventInterFace } from "types/index.types"
import RtButton from "components/button/RtButton"
import useScrollIntoView from "hooks/useScrollIntoView"
import { useEffect, useRef } from "react"
import { PERIODS, TPeriodValue } from "constants/global.constants"
import getPeriodDisplay from "utils/getPeriodDisplay.util"
import NoItemsFallback from "components/fallback/NoItemsFallback"
import RtTitle from "components/title/RtTitle"
import TicketsSold from "./ticket-list"
import { TicketDetails } from "types/component-props.types"
import { useNavigate } from "react-router-dom"

function AdminDashboard() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()
  const navigate = useNavigate()
  const period = (searchParams.get("period") ||
    PERIODS.UPCOMING.value) as TPeriodValue

  const nextPageSpinnerRef = useRef(null)

  useScrollIntoView(nextPageSpinnerRef)

  const dashboardStatsParams = removeFalsyValues({
    period,
  })
  const navigateToEvents = () => navigate(`/events`)
  const navigateToSales = () => navigate(`/sales`)
  const { isLoading, error, data } = useGetDashboardData(dashboardStatsParams)

  const {
    isLoading: isEventsLoading,
    error: eventsError,
    data: eventsData,
  } = useGetDashboardEvents({ period, limit: 10 })
  const noEvents = eventsData?.pages[0]?.data?.data.length === 0

  const title = getPeriodDisplay(period)
  useEffect(() => {
    if (!period) {
      handleSetUrlParams({ period: PERIODS.UPCOMING.value })
    }
  }, [period])

  if (eventsError || error) return <ErrorFallback />

  return (
    <>
      <SectionContainer>
        <div className="flex justify-end mb-4">
          <PeriodFilter />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-3 gap-4">
            <>
              <DashboardStatCard
                stat={data.data.stats.events}
                statDisplayName="Events"
                to="/events"
              />
              <DashboardStatCard
                stat={data.data.stats.published}
                statDisplayName="Published"
                to="/events/?status=3"
              />
              <DashboardStatCard
                stat={data.data.stats.in_review}
                statDisplayName="In Review"
                to="/events/?status=2"
              />
              <DashboardStatCard
                stat={data.data.stats.tickets}
                statDisplayName="Tickets"
                to="/sales"
              />
              <DashboardStatCard
                overrideContainerClass="col-span-2"
                stat={`${data.data.stats.ticket_commission}/${data.data.stats.ticket_sales}`}
                statDisplayName="Commission/Sales"
                to="/sales"
              />
            </>
          </div>
        )}
      </SectionContainer>

      <SectionContainer>
        {noEvents ? (
          <NoItemsFallback title="There are no events for this period" />
        ) : (
          <>
            {isEventsLoading || isLoading ? (
              <Spinner />
            ) : (
              <div className="flex flex-col lg:flex-row justify-between gap-4">
                <div className="w-full lg:w-2/3">
                  <div className="flex flex-col sm:flex-row justify-between border-b border-b-rt-light-gray">
                    <RtTitle overrideClass="mb-3">{`${title} events`}</RtTitle>
                    <i className="text-rt-gray text-sm sm:text-base">
                      Days To Event
                    </i>
                  </div>

                  {eventsData?.pages?.map((pages, idx) => (
                    <div key={idx}>
                      {pages?.data?.data.map((event: EventInterFace) => (
                        <AdminEventLink
                          period={period}
                          key={`admin-event-${event.slug}`}
                          event={event}
                        />
                      ))}
                    </div>
                  ))}

                  <SectionContainer overrideClass="flex justify-center mt-4">
                    <RtButton onClick={() => navigateToEvents()}>
                      All events
                    </RtButton>
                  </SectionContainer>
                </div>
                {data.data.stats.tickets_sold?.length > 0 ? (
                  <div className="w-full lg:w-1/3 mt-8 lg:mt-0">
                    <div className="flex flex-col sm:flex-row justify-between border-b border-b-rt-light-gray">
                      <RtTitle overrideClass="mb-3">Ticket No.</RtTitle>
                      <div className="flex justify-between sm:justify-end w-full sm:w-auto">
                        <i className="text-rt-gray text-sm sm:text-base mr-4">
                          Ticket Type
                        </i>
                        <i className="text-rt-gray text-sm sm:text-base">
                          Date Sold
                        </i>
                      </div>
                    </div>
                    {data?.data?.stats?.tickets_sold?.map(
                      (sold: Partial<TicketDetails>, idx: number) => (
                        <TicketsSold key={idx} ticket={sold} />
                      ),
                    )}
                    <SectionContainer overrideClass="flex justify-center mt-4">
                      <RtButton onClick={() => navigateToSales()}>
                        All Sales
                      </RtButton>
                    </SectionContainer>
                  </div>
                ) : (
                  <NoItemsFallback title="No tickets sold for this period" />
                )}
              </div>
            )}
          </>
        )}
      </SectionContainer>
    </>
  )
}

export default AdminDashboard
