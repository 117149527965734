import RtActionButton from "components/button/RtActionButton"
import HostKyc from "components/host-kyc"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import Spinner from "components/loader/Spinner"
import { useViewMyKyc } from "hooks/api/useViewMyKyc"
import { Edit } from "react-feather"
import { useNavigate } from "react-router-dom"

function HostKYC() {
  const { data: kycDetails, isLoading: isKycDetailsLoading } = useViewMyKyc()

  const navigate = useNavigate()

  const onEdit = () => {
    navigate("/add-kyc", { state: { defaultValues: kycDetails?.data } })
  }
  return (
    <SectionContainer>
      {isKycDetailsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-end mb-2">
            <RtActionButton
              handleClick={onEdit}
              name="Edit"
              icon={<Edit size={15} />}
            />
          </div>
          <ContentContainer>
            <HostKyc user={kycDetails?.data} />
          </ContentContainer>
        </>
      )}
    </SectionContainer>
  )
}

export default HostKYC
