import { differenceInSeconds, parseISO } from "date-fns"

export function getLockRemainingSeconds(expiresAt: string) {
  if (!expiresAt) return 0

  const now = new Date()

  const expiresAt_ = parseISO(expiresAt)

  const difference = differenceInSeconds(expiresAt_, now)

  const remainingMinutes = difference > 0 ? difference : 0

  return remainingMinutes
}
