import { useMutation } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { showToaster } from "utils/show-toaster.util"

interface UpdateEventProps {
  slug: string
  handleOnSuccess?: () => void
}

function useUpdateEvent({ slug, handleOnSuccess }: UpdateEventProps) {
  const response = useMutation({
    mutationFn: async (data: any) => {
      await handlePostRequest(`/events/${slug}/update`, data)
    },
    onSuccess: () => {
      showToaster("success", "Event updated successfully")

      handleOnSuccess && handleOnSuccess()
    },
    onError: (err: any) => {
      handleError(err)
    },
  })
  return response
}

export { useUpdateEvent }
