import CopyToClipBoard from "components/copy-to-clipboard"
import { useNavigate } from "react-router-dom"
import { getEventDetailsURL } from "utils/url.generator.util"
import RequestWithdrawalAction from "./request-withdrawal-action"
import { Edit } from "react-feather"
import { cn } from "utils/cn.util"
import RtButton from "components/button/RtButton"

interface HostEventActionsProps {
  slug: string
  event: any
  permissions: {
    canEdit: boolean
    canRequestWithdrawal: boolean
  }
  overrideClass?: string
}

function HostEventActions({
  slug,
  event,
  permissions: { canEdit, canRequestWithdrawal },
  overrideClass,
}: HostEventActionsProps) {
  const navigate = useNavigate()

  const onlyShare = !canEdit && !canRequestWithdrawal
  return (
    <div
      className={cn(
        `flex ${onlyShare ? "justify-end" : "justify-between"} items-center`,
        overrideClass,
      )}
    >
      <CopyToClipBoard
        copyText={getEventDetailsURL(slug!)}
        successMsg="The event link has successfully been copied,you can now share it with your friends & family."
      />
      {canEdit && (
        <RtButton
          variant="link"
          icon={<Edit size={15} />}
          onClick={() =>
            navigate(`/update-event/${slug}`, { state: { event } })
          }
        >
          Edit Event
        </RtButton>
      )}
      {canRequestWithdrawal && <RequestWithdrawalAction slug={slug!} />}
    </div>
  )
}

export default HostEventActions
