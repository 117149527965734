import { CartInterface } from "redux/features/cart"

function getCartCountLockId(cart: CartInterface) {
  if (!cart) {
    return { cartCount: 0, checkOutUrl: null }
  }

  const cartItem = cart[Object.keys(cart)[0]]

  return {
    cartCount: cartItem?.slugTotalCount || 0,
    checkOutUrl: cartItem?.checkOutUrl,
    slug: cartItem?.slug,
  }
}

export { getCartCountLockId }
