import RtActionButton from "components/button/RtActionButton"
import RequestWithdrawalModal from "components/modal/RequestWithdrawalModal"
import useDisclosure from "hooks/useDisclosure"

interface RequestWithdrawalActionProps {
  slug: string
}

function RequestWithdrawalAction({ slug }: RequestWithdrawalActionProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <RtActionButton handleClick={onOpen} name="Request withdrawal" />
      <RequestWithdrawalModal isOpen={isOpen} onClose={onClose} slug={slug} />
    </>
  )
}

export default RequestWithdrawalAction
