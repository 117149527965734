import { LoaderContainerProps } from "types/component-props.types"
import Spinner from "./Spinner"
import { useSelector } from "react-redux"

export default function LoaderContainer({ children }: LoaderContainerProps) {
  const loading = useSelector((state: any) => state.loading)

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-25"></div>
          <div className="relative z-10 ">
            <Spinner />
          </div>
        </div>
      )}

      <>{children}</>
    </>
  )
}
