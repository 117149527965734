import { Calendar } from "react-feather"
import { useSearchParams } from "react-router-dom"
import CustomDateModal from "../../components/modal/custom-date-modal"
import RtBaseDropDownMenu from "../../components/menu/rt-base-menu"
import useDisclosure from "../../hooks/useDisclosure"
import { PERIODS, TPeriodValue } from "constants/global.constants"
import { TPeriod } from "types/index.types"
import { cn } from "utils/cn.util"

const options = Object.values(PERIODS)

function PeriodFilter({ overrideClass }: { overrideClass?: string }) {
  const { isOpen, onToggle: onToggleCustomDateModal } = useDisclosure()
  const [searchParams, setSearchParams] = useSearchParams()
  const activePeriod = (searchParams.get("period") ||
    PERIODS.UPCOMING.value) as TPeriodValue

  const handleSelectedOption = (period: TPeriod) => {
    if (period.value === "custom") {
      onToggleCustomDateModal()

      return
    }

    onSelectDate(period.value)
  }

  const onSelectDate = (periodValue: string) => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("period", periodValue)

      return prevSearchParams
    })
  }

  const getPeriodMenuTitle = (periodString: string) => {
    let menuTitle = ""

    if (periodString.startsWith("custom")) {
      menuTitle = "Custom"
    } else {
      const period = options.find((option) => option.value === periodString)

      menuTitle = period?.label || "Date"
    }

    return menuTitle
  }

  const MenuOptions = options.map((option, index) => () => {
    const isActive = option.value === activePeriod

    return (
      <button
        key={`period-filter-${index}`}
        onClick={() => handleSelectedOption(option)}
        className={cn(
          "w-full whitespace-nowrap  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-1 border-b ",
          isActive && "text-rt-violet font-bold",

          isActive,
        )}
      >
        {option.label}
      </button>
    )
  })

  return (
    <div className={overrideClass}>
      <RtBaseDropDownMenu
        menuOptions={MenuOptions.map((Option, index) => (
          <Option key={`period-filter-${index}`} />
        ))}
        menuTitle={getPeriodMenuTitle(activePeriod)}
        icon={<Calendar size={15} />}
      />

      <CustomDateModal
        isOpen={isOpen}
        onClose={onToggleCustomDateModal}
        handleApply={onSelectDate}
      />
    </div>
  )
}

export default PeriodFilter
