import { RtFilterButtonProps } from "types/component-props.types"

function RtFilterButton({
  children,
  overrideClass = "",
  handleClick,
  isSelected,
}: RtFilterButtonProps) {
  return (
    <button
      onClick={handleClick}
      className={`bg-rt-white  rounded-lg px-4 py-1 whitespace-nowrap font-poppins text-sm w-max  ${
        isSelected
          ? "border-2 border-rt-violet outline-none text-rt-violet"
          : ""
      } shadow-xl shadow-rt-dark ${overrideClass}`}
    >
      {children}
    </button>
  )
}

export default RtFilterButton
