import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"

export function useListPlaces(place?: string) {
  const { data, isLoading, isError } = useQuery({
    enabled: !!place,
    queryKey: ["placesAutocomplete", { q: place }],
    queryFn: async () => handleGetRequest("/places-autocomplete", { q: place }),
  })

  return {
    data,
    isLoading,
    isError,
  }
}
