import { PERIODS } from "constants/global.constants"
import { EventInterFace } from "types/index.types"
import { getTimeAgoFromNow } from "utils/date-formatter.util"

interface AdminEventLinkProps {
  event: Partial<EventInterFace>
  period?: string
}
function AdminEventLink({ event, period }: AdminEventLinkProps) {
  return (
    <div className="flex justify-between py-2 border-b border-b-rt-light-gray cursor-pointer">
      <a
        className="cursor-pointer  text-rt-blue"
        href={`/my-events/${event.slug}`}
      >
        {event.name}
      </a>
      {period === PERIODS.PAST.value ? (
        <i className="text-rt-gray">
          {getTimeAgoFromNow(event.start_date!)} ago
        </i>
      ) : (
        <i className="text-rt-gray">{getTimeAgoFromNow(event.end_date!)}</i>
      )}
    </div>
  )
}

export default AdminEventLink
