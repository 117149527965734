import { useMutation, useQueryClient } from "@tanstack/react-query"
import { showToaster } from "utils/show-toaster.util"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useApproveEvent() {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (slug: string) => {
      await handlePostRequest(`/admin/events/${slug}/publish`, {})
      return { slug }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(data.slug),
      })

      showToaster("success", "You have successfully approved this event", 5000)
    },
    onError: (err) => {
      handleError(err)
    },
  })

  return mutation
}
