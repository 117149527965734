import { useMutation, useQueryClient } from "@tanstack/react-query"
import { handleError, handlePostRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"
import { showToaster } from "utils/show-toaster.util"

export function useRequestWithdrawal(slug: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await handlePostRequest(`/events/${slug}/request-withdrawal`, undefined)
    },
    onSuccess: () => {
      showToaster(
        "success",
        "Your withdrawal requests is successful,Please wait as our admin will reach out to finalize on the withdrawal.",
      )

      queryClient.invalidateQueries({
        queryKey: queryKeyGenerator.eventBySlug(slug),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
