export function getInitials(word: string) {
  if (!word) return ""

  const splitNames = word.split(" ")

  const hasTwoNames = splitNames.length == 2

  let initials = ""

  if (hasTwoNames) {
    const firstNameLetter = splitNames[0][0].toUpperCase()
    const secondNameLetter = splitNames[1][0].toUpperCase()

    initials = initials.concat(firstNameLetter, secondNameLetter)
  } else {
    const firstLetter = splitNames[0][0].toUpperCase()
    const secondLetter = splitNames[0][1].toUpperCase()

    initials = initials.concat(firstLetter, secondLetter)
  }

  return initials
}
