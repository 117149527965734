import { useSelector } from "react-redux"
import { TicketTotalProps } from "types/component-props.types"

function TicketTotal({ slug }: TicketTotalProps) {
  const cart = useSelector((state: any) => state.cart)

  const slugInCart = cart[slug]

  return (
    <div className="flex flex-row justify-end gap-8">
      <div className="flex flex-col">
        <p>Total Tickets:</p>
        <p>Total Price:</p>
      </div>
      <div className="flex flex-col items-end">
        <p className="font-bold text-rt-dark-blue">
          {slugInCart?.slugTotalCount || 0}
        </p>
        <p className="font-bold text-rt-dark-blue">
          Ksh: {slugInCart?.slugTotalPrice || 0}
        </p>
      </div>
    </div>
  )
}

export default TicketTotal
