import { useSelector } from "react-redux"

function RTError({ inputName }: { inputName: string }) {
  const formErrors = useSelector((state: any) => state?.formErrors)
  const error = formErrors[inputName]

  if (!error) return null

  return (
    <p className="text-rt-red font-poppins font-light text-sm">* {error}</p>
  )
}

export default RTError
