import { KYC_APPROVED, KYC_PENDING_APPROVAL } from "constants/global.constants"
import { useSelector } from "react-redux"
import { TUserType } from "types/enums"

function isKycAPendingOrApproved() {
  const kycStatus = useSelector((state: any) => state?.user?.has_kyc)

  return [KYC_APPROVED, KYC_PENDING_APPROVAL].includes(kycStatus)
    
}

function isUserEmailVerified() {
  const isEmailVerified = useSelector(
    (state: any) => state?.user?.email_verified,
  )

  return !!isEmailVerified
}

function useGetLoggedInUserType() {
  return useSelector((state: any) => state.user.user_type) as TUserType
}

export { isUserEmailVerified, isKycAPendingOrApproved, useGetLoggedInUserType }
