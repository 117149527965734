import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useViewKyc(hostId: number | null) {
  return useQuery({
    enabled: !!hostId,
    queryKey: queryKeyGenerator.viewKyc(hostId!),
    queryFn: async () => await handleGetRequest(`/admin/${hostId}/view-kyc`),
    throwOnError: true,
  })
}
