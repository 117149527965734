import { useState } from "react"
import SectionContainer from "components/layout/SectionContainer"
import BaseModal from "./BaseModal"
import RtButton from "components/button/RtButton"
import { useRejectKyc } from "hooks/api/useReviewKyc"
import RtTextArea from "components/input/RtTextArea"

interface RejectKycModalProps {
  hostId: number
  isOpen: boolean
  toggleModal: () => void
}

function RejectKycDetailsModal({
  hostId,
  isOpen,
  toggleModal,
}: RejectKycModalProps) {
  const [rejectReason, setRejectReason] = useState({ reject_reason: "" })

  const handleChange = (value: { [key: string]: any }) => {
    setRejectReason((prev) => ({ ...prev, ...value }))
  }

  const rejectQueryClient = useRejectKyc()
  const handleProceed = () => {
    rejectQueryClient.mutate({
      hostId,
      data: rejectReason,
    })

    if (rejectQueryClient.isSuccess) toggleModal()
  }
  return (
    <BaseModal isOpen={isOpen} onClose={toggleModal}>
      <SectionContainer overrideClass="font-poppins">
        <p className="text-md mb-4">
          Are you sure you want to <span className="text-rt-red">REJECT</span>{" "}
          to this KYC?
        </p>

        <RtTextArea
          inputName="reject_reason"
          inputPlaceholder="Enter a reason for rejection"
          inputOverrideClass="bg-rt-white bg-opacity-100 "
          label="Reason"
          inputValue={rejectReason?.reject_reason || ""}
          onInputChange={handleChange}
          isRequired
        />

        <div className="flex justify-end gap-10 mt-8">
          <RtButton
            onClick={toggleModal}
            overrideClass="bg-rt-white border border-rt-red"
            textOverrideClass="text-rt-red"
          >
            Cancel
          </RtButton>
          <RtButton
            onClick={handleProceed}
            disabled={rejectQueryClient.isPending}
          >
            {rejectQueryClient.isPending ? "Processing..." : "Proceed"}
          </RtButton>
        </div>
      </SectionContainer>
    </BaseModal>
  )
}

export default RejectKycDetailsModal
