import { useMemo } from "react"
import { SalesInterface } from "types/index.types"
import { getTimeAgoFromNow } from "utils/date-formatter.util"
import { ADMIN } from "constants/global.constants"
import { SalesTableProps } from "types/component-props.types"
import BaseTable from "./BaseTable"

function SalesTable({
  data,
  userType,
  currentPage,
  totalPages,
  onPageChange,
  setRowsHandler,
  rowsPerPage,
}: SalesTableProps) {
  const columns = useMemo(
    () => [
      {
        key: "ticket_ids",
        header: "Ticket No",
        render: (sales: SalesInterface) => `#${sales.ticket_ids.split(",")[0]}`,
      },
      { key: "ticket_type", header: "Ticket Type" },
      ...(userType === ADMIN ? [{ key: "buyer", header: "Ticket User" }] : []),
      { key: "total_tickets", header: "Qty" },
      {
        key: "price",
        header: "Cost",
        render: (sales: SalesInterface) => `${sales.price}`,
      },
      {
        key: "total_price",
        header: "Total Earned",
        render: (sales: SalesInterface) => `${sales.total_price}`,
      },
      {
        key: "total_commission",
        header: "Commission",
        render: (sales: SalesInterface) => `${sales.total_commission}`,
      },
      {
        key: "sold_at",
        header: "Date/Time",
        render: (sales: SalesInterface) =>
          `${getTimeAgoFromNow(sales.sold_at)} ago`,
      },
    ],
    [userType],
  )

  return (
    <BaseTable
      columns={columns}
      data={data}
      keyExtractor={(sales: SalesInterface) =>
        `${sales.ticket_ids}-${sales.sold_at}`
      }
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      setRowsHandler={setRowsHandler}
      rowsPerPage={rowsPerPage}
    />
  )
}

export default SalesTable
