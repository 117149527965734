function removeFalsyValues(obj?: Record<string, any>): Record<string, any> {
  const newObj = { ...obj }
  for (const key in newObj) {
    if (
      newObj[key] === "" ||
      newObj[key] === null ||
      newObj[key] === undefined ||
      (Array.isArray(newObj[key]) && newObj[key].length === 0)
    ) {
      delete newObj[key]
    }
  }
  return newObj
}

export default removeFalsyValues
