import { useQuery, useQueryClient } from "@tanstack/react-query"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import { handleGetRequest } from "utils/api-handler.util"

interface ParamsInterface {
  is_active: 1 | 0
}

function useListMyEvents({ is_active }: ParamsInterface) {
  const queryClient = useQueryClient();
  const { searchParams } = useRtSetUrlParams()
  const q = searchParams.get("q")

  const params = { q, is_active }

  const { isLoading, data, error } = useQuery({
    queryKey: ["listMyEvents", params],
    queryFn: async () => await handleGetRequest("/events/mine", params),
    staleTime: 1000 * 60 * 2,
    placeholderData: (previousData) =>
      previousData || queryClient.getQueryData(["listMyEvents", params]),

  })

  return {
    isLoading,
    data,
    error,
  }
}

export default useListMyEvents
