import ReactSelect from "react-select"
import { IGender } from "types/index.types"

const options = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Other",
    label: "Other",
  },
]

interface GenderSearchSelectProps {
  selectedGender: IGender
  handleSelectedGenderChange: (newValue: IGender | null) => void
}

function GenderSearchSelect({
  selectedGender,
  handleSelectedGenderChange,
}: GenderSearchSelectProps) {
  return (
    <ReactSelect
      value={selectedGender}
      onChange={(newValue) => {
        handleSelectedGenderChange(newValue as IGender)
      }}
      options={options}
      getOptionLabel={(option: IGender) => option?.label || ""}
      getOptionValue={(option: IGender) => option?.value || ""}
      isClearable
      isSearchable
      placeholder="Select gender"
    />
  )
}

export default GenderSearchSelect


export function createGenderOption(value: string) {
  const transformed = value.charAt(0).toUpperCase() + value.slice(1)
  return {
    value: transformed,
    label: transformed,
  }
}
