import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"

export function useViewMyKyc() {
  return useQuery({
    queryKey: ["my-kyc"],
    queryFn: async () => await handleGetRequest(`/my-kycs`),
    throwOnError: true,
  })
}
