import RtTitle from "components/title/RtTitle"
import SectionContainer from "components/layout/SectionContainer"
import { motion } from "framer-motion"
import { useListSoldTickets } from "hooks/api/useListTicketSales"
import Spinner from "components/loader/Spinner"
import NoItemsFallback from "components/fallback/NoItemsFallback"
import { useGetLoggedInUserType } from "hooks/useUser"
import { useState } from "react"
import SalesTable from "components/tables/SalesTable"
import { useSelector } from "react-redux"
import ExportDropdown from "components/drowpdowns/ExportDropdown"
import exportToPDF from "utils/pdf-generator.util"
import exportToExcel from "utils/excel-generator.util"
import exportToCSV from "utils/csv-generator.util"

function Sales() {
  const userType = useGetLoggedInUserType()
  const user = useSelector((state: any) => state.user)

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const { data, isLoading } = useListSoldTickets(
    currentPage,
    rowsPerPage,
    user.id,
  )
  const setRowsHandler = (rows: number) => {
    setRowsPerPage(rows)
    setCurrentPage(1)
  }
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }
  const noSales = data?.data?.data?.length === 0
  const totalPages = data?.data?.meta?.last_page ?? 1

  function handleExport(fileExt: string) {
    const exportFunctions: Record<
      string,
      (data: any, userType: string) => void
    > = {
      pdf: exportToPDF,
      excel: exportToExcel,
      csv: exportToCSV,
    }

    exportFunctions[fileExt]?.(data?.data.data, userType)
  }

  return (
    <SectionContainer>
      {noSales ? (
        <NoItemsFallback title="No tickets sales yet" />
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <div className="flex items-center gap-2 mb-4 sm:mb-0">
              <RtTitle>Sales</RtTitle>
            </div>
            {/* <button
              onClick={handleExport}
              className="px-4 py-2 bg-white text-gray-600 border rounded-md hover:bg-gray-50 w-full sm:w-auto"
            >
              Export
            </button> */}
            <div className="flex items-center gap-4">
              <ExportDropdown onExport={handleExport} />
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <SalesTable
              data={data?.data?.data}
              userType={userType}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isFetching={isLoading}
              setRowsHandler={setRowsHandler}
              rowsPerPage={rowsPerPage}
            />
          )}
        </motion.div>
      )}
    </SectionContainer>
  )
}
export default Sales
